<script setup lang="ts">
import VueDatePicker, { type VueDatePickerProps } from '@vuepic/vue-datepicker';
import { useI18n } from 'vue-i18n';
import useDate from '@/composables/useDate';
import { computed, nextTick } from 'vue';
import { DateTime } from 'luxon';

type Props = {
  // Custom
  id?: string;
  size?: string;
  invalid?: boolean;
  placeholder?: string;
  textInput?: boolean;
  // DatePicker
  clearable?: VueDatePickerProps['clearable'];
  required?: VueDatePickerProps['required'];
  disabled?: VueDatePickerProps['disabled'];
  minDate?: VueDatePickerProps['minDate'];
  maxDate?: VueDatePickerProps['maxDate'];
  position?: VueDatePickerProps['position'];
  teleport?: VueDatePickerProps['teleport'];
};

const emit = defineEmits<{
  change: [value: null | number];
}>();

const { t, locale } = useI18n({ useScope: 'global' });

const { placeholder, textInput = true, size = 'default' } = defineProps<Props>();

const { convertWeekNumberToDateRange, convertDateRangeToWeekNumber } = useDate();

const model = defineModel<null | number>({ required: true });

const modelValue = computed(() => {
  return model.value ? convertWeekNumberToDateRange(model.value) : null;
});

function onModelUpdate(dates: [Date, Date] | null) {
  model.value = dates ? convertDateRangeToWeekNumber(dates) : null;
  nextTick().then(() => {
    emit('change', model.value);
  });
}

const textInputOptions = {
  format(input: string) {
    let value = input.replace('-', '');
    if (value.length === 5) {
      value = value.slice(0, -1) + '0' + value.slice(-1);
    }
    const date = DateTime.fromFormat(value, 'kkkkWW');
    return date.isValid ? date.toJSDate() : null;
  },
};
</script>

<template>
  <div class="form-wrapper has-icon d-flex align-items-center" :class="[size ? `is-${size}` : '']">
    <VueDatePicker
      :uid="id"
      :ui="{ input: ['form-control', invalid ? 'is-invalid' : ''] }"
      :model-value="modelValue"
      @update:model-value="onModelUpdate"
      :placeholder="placeholder ?? t('common.select')"
      :enable-time-picker="false"
      :month-change-on-scroll="false"
      :week-num-name="t('common.week_short')"
      format="RR-II"
      week-numbers="iso"
      :locale="locale"
      week-picker
      auto-apply
      :text-input="textInput ? textInputOptions : textInput"
      :clearable="clearable"
      :disabled="disabled"
      :required="required"
      :teleport="teleport"
      :position="position"
      :min-date="minDate"
      :max-date="maxDate"
      six-weeks="center"
    >
      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
    </VueDatePicker>
  </div>
</template>
