export default {
  modal: {
    modes: {
      title: 'Rapportera tid',
      project_task: 'Kunduppgift',
      absence: 'Frånvaro',
      activity: 'Interntid',
    },
    project_task: {
      create_title: 'Skapa ny kunduppgift',
      edit_title: 'Redigera kunduppgift: {name}',
    },
    activity: {
      create_title: 'Skapa ny inten tidsrapportering',
      edit_title: 'Redigera interntid: {name}',
    },
    split: 'Splitta uppgift',
    task_block_title: 'Uppgift',
    additional_info_title: 'Ytterligare info',
    internal_block_title: 'Interntid',
    price_block_title: 'Pris',
    project_base_fee: 'Huvudpris på tjänsten',
    project_fee: 'Pris per år',
    time_planned_block_title: 'Planerad tid',
    time_entries_block_title: 'Tidregistreringar',
    time_over_budget_block_title: 'Summering - fördelning av tid utöver estimerad',
    attributes: {
      activity: 'Aktivitet',
      activity_type: 'Typ',
      activity_group: 'Grupp',
      client: 'Kund',
      project: 'Projekt',
      task: 'Uppgift',
      frequency: 'Frekvens',
      task_notes: 'Uppgiftsnoteringar',
      sub_label: 'Undernamn',
      activity_notes: 'Aktivitetsnotering',
      service_price: 'Tjänstens pris',
      charge_separately: 'Debitera extra',
      price_type: 'Pristyp',
      employee: 'Anställd',
      week: 'Vecka',
      start_week: 'Startvecka',
      end_week: 'Slutvecka',
      estimated_time: 'Uppskattad tid',
      total_time: 'Summa tid',
      done: 'Klar',
      amount: 'Belopp, SEK',
      discount: 'Rabatt, %',
      date: 'Datum',
      time: 'Tid',
      comment: 'Kommentar',
      actions: 'Åtgärder',
      billable: 'Debiterbar',
      not_billable: 'Ej debiterbar',
      note: 'Notering för fakturering',
      change_future_responsible: 'Ändra framtida ansvarig?',
      change_future_scheduled_time: 'Ändra framtida planerad tid?',
      change_future_to_actual_time: 'Ändra framtida till faktiskt tid?',
      change_future_to_actual_time_description: 'Planerad tid är för kort för uppgiften',
      deadline: 'Deadline',
      visit_is_booked: 'Besök är bokat',
      add_mileage_allowance: 'Lägg till milersättning',
      vehicle: 'Fordon',
      mileage: 'Körsträcka (km)',
      reimbursement: 'Ersättning',
    },
    split_events: {
      title: 'Splittade uppgifter',
      original_scheduled_time: 'Urspungligt planerad tid',
      week: 'Vecka',
      time: 'Färdig tid',
      employee: 'Av vem',
    },
  },
  confirm: {
    cancel: {
      title: 'Släng ändringar',
      text: 'Är du säker på att du vill slänga ändringar? All ej sparad information kommer försvinna.',
    },
  },
};
