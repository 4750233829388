<script lang="ts" setup>
import { computed, nextTick, useTemplateRef } from 'vue';
import { clsx } from 'clsx';

import AppButton from '@/components/AppButton.vue';
import FontIcon from '@/components/FontIcon.vue';
import useTime from '@/composables/useTime';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['change']);
const inputNodeRef = useTemplateRef<HTMLInputElement>('input');

const {
  size = null,
  max = 5985,
  min = 0,
  disabled = false,
  step = 15,
  invalid = false,
  withControls = true,
  placeholder = '00:00',
  inputSize = 4,
} = defineProps<{
  size?: null | 'small' | 'large';
  max?: number;
  min?: number;
  disabled?: boolean;
  step?: number;
  invalid?: boolean;
  withControls?: boolean;
  placeholder?: string;
  inputSize?: number;
}>();

const modelValue = defineModel<number | null>({
  required: true,
});

const { convertMinutesToTime, parseMinutes } = useTime();

function increase(value: number) {
  modelValue.value = (modelValue.value ?? 0) + value >= max ? max : (modelValue.value ?? 0) + value;
  nextTick(() => {
    emit('change', modelValue.value);
  });
}

function decrease(value: number) {
  modelValue.value = (modelValue.value ?? 0) - value <= min ? min : (modelValue.value ?? 0) - value;
  nextTick(() => {
    emit('change', modelValue.value);
  });
}

function onChange(event: Event) {
  const input = event.target as HTMLInputElement;
  const { value } = input;
  const minutes = parseMinutes(value);
  if (minutes === 0 || Number.isNaN(minutes)) {
    modelValue.value = 0;
    input.value = '';
  } else {
    if (minutes >= max) {
      input.value = convertMinutesToTime(max.toString());
      modelValue.value = max;
    } else if (minutes <= min) {
      input.value = convertMinutesToTime(min.toString());
      modelValue.value = min;
    } else {
      input.value = convertMinutesToTime(minutes.toString());
      modelValue.value = minutes;
    }
  }
  nextTick(() => {
    emit('change', modelValue.value);
  });
}

const classes = computed(() =>
  clsx('form-wrapper', 'd-flex', 'align-items-center', {
    [`is-${size}`]: size,
  }),
);

function focus() {
  inputNodeRef.value?.focus();
}

function blur() {
  inputNodeRef.value?.blur();
}

defineExpose({ focus, blur });

function onFocus() {
  if (modelValue.value === 0) {
    inputNodeRef.value!.value = '';
  } else {
    inputNodeRef.value!.select();
  }
}
function onBlur() {
  if (modelValue.value === 0 && inputNodeRef.value) {
    inputNodeRef.value.value = '00:00';
  }
}
</script>

<template>
  <div :class="classes">
    <AppButton
      v-if="withControls"
      @click.prevent="decrease(step)"
      type="button"
      light
      circle
      size="small"
      class="mr-1"
      :disabled="disabled || (modelValue ?? 0) <= min"
    >
      <FontIcon name="minus" />
    </AppButton>
    <input
      v-bind="$attrs"
      class="form-control text-center w-auto"
      :class="{ 'is-invalid': invalid }"
      :value="modelValue === null ? null : convertMinutesToTime(modelValue ?? 0)"
      @keydown.enter.prevent="inputNodeRef?.blur"
      :placeholder="placeholder"
      :size="inputSize"
      :disabled="disabled"
      @change="onChange"
      ref="input"
      @focus="onFocus"
      @blur="onBlur"
    />
    <AppButton
      v-if="withControls"
      @click.prevent="increase(step)"
      type="button"
      light
      circle
      size="small"
      class="ml-1"
      :disabled="disabled || (modelValue ?? 0) >= max"
    >
      <FontIcon name="plus" />
    </AppButton>
  </div>
  <slot />
</template>
