import { IProjectTaskEventRequestBody } from '@/types/Project';
import { IUserPreviewResource } from '@/types/User';
import { IServiceTaskResource } from '@/types/Service';
import { RepetitionFrequencyType } from '@/types/Common';

export enum ProjectTaskPriceType {
  HOURLY_DISCOUNT = 'hourly_discount',
  FIXED = 'fixed',
}

export interface IPlanningEventResource {
  id: number;
  week: number;
  scheduled_time: number;
  user_uuid: string;
  is_done: boolean;
  is_invoiced: boolean;
  has_tracked_time: boolean;
  is_deleted?: boolean;
  deadline: null | string;
}

export interface IPlanningEventView extends Omit<IPlanningEventResource, 'id' | 'week' | 'user_uuid'> {
  uid: string;
  id?: number;
  week: null | number;
  user_uuid: null | string;
  parent_event_id?: number;
  is_planned?: boolean;
  is_deleted?: boolean;
}

// Project task
export interface IProjectTaskResource {
  id: number;
  order: null | number;
  name: string;
  sub_label: null | string;
  default_time_budget: null | number;
  default_frequency: null | RepetitionFrequencyType;
  task_week: number;
  custom_price: boolean;
  custom_price_type: null | ProjectTaskPriceType;
  custom_price_value: null | number | string;
  note: null | string;
  serviceTask: IServiceTaskResource;
  user: null | IUserPreviewResource;
  events: IPlanningEventResource[];
  has_not_activated_changes: boolean;
}

export interface IProjectTaskSummaryView
  extends Pick<
    IProjectTaskResource,
    | 'id'
    | 'name'
    | 'default_frequency'
    | 'task_week'
    | 'custom_price'
    | 'custom_price_type'
    | 'custom_price_value'
    | 'user'
    | 'order'
    | 'default_time_budget'
  > {
  uid: string;
  is_planned: boolean;
  is_deleted: boolean;
}

// Planned project task
export interface IPlannedProjectTaskResource extends Omit<IProjectTaskResource, 'has_not_activated_changes'> {
  parent_project_task_id: null | number;
  is_deleted: boolean;
}

// Project task request body
export interface IProjectUpdateTaskRequest {
  id?: number;
  parent_project_task_id?: null | number;
  is_planned?: boolean;
  order: null | number;
  name: null | string;
  sub_label: null | string;
  default_time_budget: null | number;
  default_frequency: null | RepetitionFrequencyType;
  task_week: null | number;
  custom_price: boolean;
  custom_price_type: null | ProjectTaskPriceType;
  custom_price_value: null | number;
  events: IProjectTaskEventRequestBody[];
  service_task_id: null | number;
  user_uuid: null | string;
}

// Views
export interface IProjectTaskPlanningView extends IProjectUpdateTaskRequest {
  uid: string;
  has_not_activated_changes?: boolean;
  events: IPlanningEventView[];
  collapsed: boolean;
  loading: boolean;
  is_deleted: boolean;
}

export interface ProjectTaskModalProps {
  id?: number;
  initialWeek?: number;
  addTrackedTimeOnOpen?: { date: string; time: number };
}

export type IProjectTaskPreviewResource = Pick<IProjectTaskResource, 'id' | 'name' | 'sub_label'>;
