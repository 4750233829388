<script setup lang="ts">
import { onMounted, ref, computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal, useModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';

import api from '@/services/api';
import {
  AppButton,
  AppCloseButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppPagination,
  AppAlert,
  FontIcon,
  ConfirmModal,
  FormLabel,
  FormNumber,
} from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { IUserPriceChange, IUserPriceChangeRequest, UserPriceType } from '@/types/User';

type Props = {
  type: UserPriceType;
  history: IUserPriceChange[];
  initialDate: null | string;
  initialPrice: null | number;
  disabledDate: boolean;
  disabledPrice: boolean;
};

const { type, history, initialDate, initialPrice } = defineProps<Props>();

const isInternal = computed(() => type === UserPriceType.INTERNAL);
const isExternal = computed(() => type === UserPriceType.EXTERNAL);

const emit = defineEmits<{
  apply: [userPrice: IUserPriceChangeRequest];
  deleted: [priceId: number];
  clear: [];
  closed: [];
}>();

const priceChanges = ref<IUserPriceChange[]>([]);

const { t, d, locale } = useI18n({ useScope: 'global' });

const page = ref(1);
const perPage = ref(8);
const total = ref(0);

const paginated = computed(() => {
  const offset = (page.value - 1) * perPage.value;
  return priceChanges.value.slice(offset, offset + perPage.value);
});

const form = reactive<IUserPriceChangeRequest>({
  price: null,
  apply_date: null,
});

const disabled = computed(() => !(form.price && form.apply_date));

async function submit() {
  emit('apply', form);
}

function onEdit(price: IUserPriceChangeRequest) {
  form.price = price.price;
  form.apply_date = price.apply_date;
}

function onDeletePrice(price: IUserPriceChange) {
  const { open, destroy, close } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('user.confirm.destroy_price.title'),
      message: t('user.confirm.destroy_price.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.users.prices.destroy(price.id);
          priceChanges.value = priceChanges.value.filter(({ id }) => id !== price.id);
          total.value = priceChanges.value.length;
          if (paginated.value.length === 0 && page.value > 1) {
            page.value -= 1;
          }
          emit('deleted', price.id);
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(() => {
  if (initialDate) {
    form.apply_date = initialDate;
  }
  if (initialPrice) {
    form.price = initialPrice;
  }
  total.value = history.length;
  priceChanges.value = [...history];
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-large"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @keydown.enter.prevent @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-if="isInternal" v-text="t('user.attributes.internal_price')" />
        <h2 v-if="isExternal" v-text="t('user.attributes.hourly_price')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <div class="row" style="min-height: 300px">
          <div class="col-md">
            <h3 v-if="isInternal" v-text="t('user.internal_price.title')" />
            <h3 v-if="isExternal" v-text="t('user.external_price.title')" />

            <!-- Date -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-5">
                  <FormLabel html-for="dp-input-date" required>
                    <span v-if="isInternal" v-text="t('user.internal_price.form.date')" />
                    <span v-if="isExternal" v-text="t('user.external_price.form.date')" />
                  </FormLabel>
                </div>
                <div class="col">
                  <div class="form-wrapper has-icon">
                    <VueDatePicker
                      :ui="{ input: 'form-control' }"
                      :placeholder="t('common.select')"
                      v-model="form.apply_date"
                      model-type="format"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      auto-apply
                      text-input
                      :locale="locale"
                      uid="date"
                      required
                      format="yyyy-MM-01"
                      month-picker
                      :week-num-name="t('common.week_short')"
                      :disabled="disabledDate"
                      six-weeks="center"
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <!-- Price -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-5">
                  <FormLabel html-for="rate" required>
                    <span v-if="isInternal" v-text="t('user.internal_price.form.price')" />
                    <span v-if="isExternal" v-text="t('user.external_price.form.price')" />
                  </FormLabel>
                </div>
                <div class="col">
                  <FormNumber
                    :length="8"
                    id="rate"
                    v-model="form.price"
                    :min="0"
                    :max="1000000"
                    :step="50"
                    :disabled="disabledPrice"
                  />
                </div>
              </div>
            </div>

            <!-- Clear -->
            <AppButton @click.prevent="emit('clear')" light :disabled="!(form.price && form.apply_date)">
              {{ t('common.clear') }}
            </AppButton>
          </div>
          <div class="col-md">
            <h3 v-text="t('user.work_rates.history.title')" />
            <AppAlert v-if="priceChanges.length === 0">{{ t('common.empty') }}</AppAlert>
            <AppTable v-else hoverable>
              <AppTableHead>
                <AppTableTr>
                  <AppTableTh nowrap>
                    <span v-if="isInternal" v-text="t('user.internal_price.attributes.date')" />
                    <span v-if="isExternal" v-text="t('user.external_price.attributes.date')" />
                  </AppTableTh>
                  <AppTableTh nowrap class="text-right">
                    <span v-if="isInternal" v-text="t('user.internal_price.attributes.price')" />
                    <span v-if="isExternal" v-text="t('user.external_price.attributes.price')" />
                  </AppTableTh>
                  <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
                </AppTableTr>
              </AppTableHead>
              <AppTableBody>
                <AppTableTr v-for="item in paginated" :key="item.id">
                  <AppTableTd nowrap>{{ d(item.apply_date) }}</AppTableTd>
                  <AppTableTd nowrap class="text-right">{{ item.price }}</AppTableTd>
                  <AppTableTd nowrap class="text-right">
                    <AppButton
                      v-tooltip.left="t('common.edit')"
                      @click.stop.prevent="onEdit(item)"
                      size="small"
                      light
                      circle
                    >
                      <FontIcon name="pencil" />
                    </AppButton>
                    <AppButton
                      v-tooltip.left="t('common.delete')"
                      @click.stop.prevent="onDeletePrice(item)"
                      class="ml-2"
                      color="danger"
                      size="small"
                      light
                      circle
                      v-if="item.deletable"
                    >
                      <FontIcon name="trash" />
                    </AppButton>
                  </AppTableTd>
                </AppTableTr>
              </AppTableBody>
            </AppTable>
            <AppPagination :per-page="perPage" :total="total" v-model="page" />
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close">
            {{ t('common.close') }}
          </AppButton>
          <AppButton class="ml-auto" color="success" :disabled="disabled">
            {{ t('common.apply') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
