<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {
  AppBox,
  AppBoxBody,
  AppButton,
  FontIcon,
  PlanningProgressMultiBar,
  TimelineRow,
  TimelineTime,
} from '@/components';
import useDate from '@/composables/useDate';
import { IEventTimelineWeek } from '@/types/Event';
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import toast from '@/services/toast';

type Props = {
  expanded: boolean;
  weekNumber: string;
  data: IEventTimelineWeek;
  isCurrent?: boolean;
  isCompleted?: boolean;
};

const { expanded, weekNumber, data, isCurrent = false, isCompleted = false } = defineProps<Props>();

const emit = defineEmits<{
  (e: 'toggle', weekNumber: string): void;
  (e: 'markWeekAsDone', weekNumber: string): void;
  (e: 'dropEvents', events: number[], weekNumber: string, dragEvent: DragEvent): void;
}>();

const { t, d } = useI18n({ useScope: 'global' });
const {
  convertWeekNumberToDates,
  getCurrentYearAndWeek,
  getFirstDateOfWeek,
  isWeekLessThanCurrent,
  isWeekGreaterThanCurrent,
} = useDate();

const over = ref(false);

function dragover(dragEvent: DragEvent) {
  dragEvent.stopPropagation();
  dragEvent.preventDefault();
  over.value = true;
}

function dragleave(dragEvent: DragEvent) {
  dragEvent.stopPropagation();
  dragEvent.preventDefault();
  over.value = false;
}

const droppable = computed(() => Number(weekNumber) >= getCurrentYearAndWeek());

function drop(dragEvent: DragEvent) {
  if (!dragEvent.dataTransfer) return;
  dragEvent.stopPropagation();
  dragEvent.preventDefault();
  const fromWeek = dragEvent.dataTransfer.getData('from_week');
  const source = dragEvent.dataTransfer.getData('source');

  const events: number[] = [];

  dragEvent.dataTransfer
    .getData('events')
    .split(',')
    .map(Number)
    .forEach((id) => {
      events.push(id);
    });

  if (source === 'project' || source === 'project_task') {
    const startDate = DateTime.fromISO(dragEvent.dataTransfer.getData('start_date'));
    const startWeek = Number(`${startDate.toFormat('kkkk')}${startDate.weekNumber.toString().padStart(2, '0')}`);

    const endDate = DateTime.fromISO(dragEvent.dataTransfer.getData('end_date'));
    const endWeek = Number(`${endDate.toFormat('kkkk')}${endDate.weekNumber.toString().padStart(2, '0')}`);
    if (Number(weekNumber) > endWeek || Number(weekNumber) < startWeek) {
      over.value = false;
      toast.error(t('common.messages.forbidden_to_move'));
      return;
    }
  }

  // if (events.length === 0 || fromWeek === weekNumber || Number(weekNumber) < getCurrentYearAndWeek()) {
  if (events.length === 0 || fromWeek === weekNumber) {
    over.value = false;
    toast.error(t('common.messages.forbidden_to_move'));
    return;
  }

  emit('dropEvents', events, weekNumber, dragEvent);
}
</script>

<template>
  <AppBox
    :class="{ droppable, over, current: isCurrent, expanded }"
    @drop.prevent="drop"
    @dragenter.prevent
    @dragover.prevent="dragover"
    @dragleave.prevent="dragleave"
    :shadow="isCurrent"
    class="timeline-week"
  >
    <AppBoxBody>
      <TimelineRow>
        <template #name>
          <div @click.prevent="emit('toggle', weekNumber)" class="d-flex align-items-center pointer">
            <div
              :class="{
                'text-success-500': isCurrent,
                'text-danger-500': !isCurrent && !isCompleted && isWeekLessThanCurrent(+weekNumber),
              }"
              class="mb-0 font-bold text-5"
            >
              {{ t('common.week_short') }}.{{ getFirstDateOfWeek(weekNumber).toFormat('W') }}
            </div>
            <span class="ml-2 text-6 text-neutral-500">
              ({{ d(convertWeekNumberToDates(+weekNumber)[0], 'short') }}
              -
              {{ d(convertWeekNumberToDates(+weekNumber)[1], 'short') }})
            </span>
          </div>
        </template>
        <template #actions>
          <AppButton
            v-if="!isCompleted && !isWeekGreaterThanCurrent(+weekNumber)"
            v-tooltip.left="t('common.mark_week_as_done')"
            size="small"
            @click.prevent="emit('markWeekAsDone', weekNumber)"
            light
            circle
            color="success"
          >
            <FontIcon name="browser-check" />
          </AppButton>
          <AppButton size="small" @click.prevent="emit('toggle', weekNumber)" light circle>
            <FontIcon v-if="expanded" name="chevron-up" />
            <FontIcon v-else name="chevron-down" />
          </AppButton>
        </template>
        <template #time>
          <TimelineTime
            :value="data.time.budgeted"
            :class="{
              'text-danger-500': data.time.budgeted > data.user_working_time_minutes,
            }"
          />
        </template>
        <template #progress>
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </template>
      </TimelineRow>
    </AppBoxBody>
    <slot />
  </AppBox>
</template>

<style lang="scss">
.timeline-week {
  &.current {
    border: 1px solid var(--color-neutral-300-hex);
  }
  &:not(.current) {
    background-color: var(--color-neutral-50-hex);
  }
  &.expanded {
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  &.droppable {
    &.over {
      background-color: var(--color-neutral-100);
    }
  }
}
</style>
