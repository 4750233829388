import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IServiceResource,
  IServicePreviewResource,
  IServiceStoreRequest,
  IServiceUpdateRequest,
  IFixedPriceSettingsResource,
  IFixedPriceSettingsRequest,
} from '@/types/Service';

export default {
  index(options: Options = {}) {
    return httpClient.get(route('services.index'), options).json<IResponse<IServicePreviewResource[]>>();
  },
  get(service: string, options: Options = {}) {
    return httpClient.get(route('services.get', { service }), options).json<IResponse<IServiceResource>>();
  },
  store(json: IServiceStoreRequest, options: Options = {}) {
    return httpClient.post(route('services.store'), { ...options, json }).json<IResponse<IServiceResource>>();
  },
  update(service: string, json: IServiceUpdateRequest, options: Options = {}) {
    return httpClient
      .patch(route('services.update', { service }), { ...options, json })
      .json<IResponse<IServiceResource>>();
  },
  updateComment(service: string, json: { notes: string | null }, options: Options = {}) {
    return httpClient
      .patch(route('services.update', { service }), { ...options, json })
      .json<IResponse<IServiceResource>>();
  },
  destroy(service: string, options: Options = {}) {
    return httpClient.delete(route('services.delete', { service }), options).json<void>();
  },

  fixedPriceSettings: {
    index(options: Options = {}) {
      return httpClient
        .get(route('services.fixed-price-settings.index'), options)
        .json<IResponse<IFixedPriceSettingsResource[]>>();
    },
    current(options: Options = {}) {
      return httpClient
        .get(route('services.fixed-price-settings.current'), options)
        .json<IResponse<null | IFixedPriceSettingsResource>>();
    },
    create(json: IFixedPriceSettingsRequest, options: Options = {}) {
      return httpClient
        .post(route('services.fixed-price-settings.store'), { ...options, json })
        .json<IResponse<IFixedPriceSettingsResource>>();
    },
    update(fixedPriceSettings: number, json: IFixedPriceSettingsRequest, options: Options = {}) {
      return httpClient
        .put(route('services.fixed-price-settings.update', { fixedPriceSettings }), { ...options, json })
        .json<IResponse<IFixedPriceSettingsResource>>();
    },
    delete(fixedPriceSettings: number, options: Options = {}) {
      return httpClient
        .delete(route('services.fixed-price-settings.delete', { fixedPriceSettings }), options)
        .json<void>();
    },
  },
};
