<script lang="ts" setup>
import { clsx } from 'clsx';

const { name, image, size } = defineProps<{
  name: string;
  image?: string;
  size?: 'small' | 'large';
}>();

function getInitials() {
  return name
    .split(' ')
    .map((n) => n[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
}
</script>

<template>
  <component
    :is="image ? 'img' : 'div'"
    :class="clsx('avatar', { [`is-${size}`]: size, initials: !image })"
    :aria-label="name"
    :src="image"
  >
    <template v-if="!image">
      {{ getInitials() }}
    </template>
    <slot />
  </component>
</template>
