<script setup lang="ts">
import VueDatePicker, { type VueDatePickerProps } from '@vuepic/vue-datepicker';
import { useI18n } from 'vue-i18n';

type Props = {
  // Custom
  id?: string;
  size?: string;
  invalid?: boolean;
  placeholder?: string;
  textInput?: boolean;
  // DatePicker
  format?: VueDatePickerProps['format'];
  clearable?: VueDatePickerProps['clearable'];
  required?: VueDatePickerProps['required'];
  disabled?: VueDatePickerProps['disabled'];
  minDate?: VueDatePickerProps['minDate'];
  maxDate?: VueDatePickerProps['maxDate'];
  position?: VueDatePickerProps['position'];
  teleport?: VueDatePickerProps['teleport'];
};

defineEmits<{
  change: [value: null | number];
}>();

const { t, locale } = useI18n({ useScope: 'global' });

const { placeholder, textInput = true, size = 'default', format = 'yyyy-MM-dd' } = defineProps<Props>();

const model = defineModel<null | string>({ required: true });
</script>

<template>
  <div class="form-wrapper has-icon d-flex align-items-center" :class="[size ? `is-${size}` : '']">
    <VueDatePicker
      :uid="id"
      :ui="{ input: ['form-control', invalid ? 'is-invalid' : ''] }"
      v-model="model"
      :placeholder="placeholder ?? t('common.select')"
      :enable-time-picker="false"
      :month-change-on-scroll="false"
      :week-num-name="t('common.week_short')"
      model-type="format"
      :format="format"
      week-numbers="iso"
      :locale="locale"
      auto-apply
      :text-input="textInput"
      :clearable="clearable"
      :disabled="disabled"
      :required="required"
      :teleport="teleport"
      :position="position"
      :min-date="minDate"
      :max-date="maxDate"
      six-weeks="center"
    >
      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
    </VueDatePicker>
  </div>
</template>
