<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useTitle } from '@vueuse/core';
import useLoader from '@/composables/useLoader';
import {
  AppAlert,
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTh,
  AppTableTr,
  AppTableTd,
  FontIcon,
  AppLoader,
  ConfirmModal,
  LinkServiceModal,
  HelpInformation,
  CommentModal,
} from '@/components';
import api from '@/services/api';
import {
  ICrmPlannerServiceMapListResource,
  ICrmPlannerServiceMapRequestBody,
  ICrmServiceListResource,
} from '@/types/Crm';
import { IServicePreviewResource } from '@/types/Service';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const { t } = useI18n({ useScope: 'global' });

const loader = useLoader();
const title = useTitle(computed(() => t('service-mapping.index.mapped_services_title')));

const mappedServicesLoader = useLoader({ useProgress: false });
const mappedServices = ref<ICrmPlannerServiceMapListResource[]>([]);

const crmServicesLoader = useLoader({ useProgress: false });
const crmServices = ref<ICrmServiceListResource[]>([]);

const servicesLoader = useLoader({ useProgress: false });
const services = ref<IServicePreviewResource[]>([]);

function onCreateMappedService() {
  const { open, close, destroy } = useModal({
    component: LinkServiceModal,
    attrs: {
      excludeCrmServices: mappedServices.value.reduce((uuids, item) => {
        return [...uuids, ...item.crm_services.map(({ uuid }) => uuid)];
      }, [] as string[]),
      async onConfirm(form: ICrmPlannerServiceMapRequestBody, { setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.crm.mappedServices.store(form);
          await getData();
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEditMappedService(mappedService: ICrmPlannerServiceMapListResource) {
  const { open, close, destroy } = useModal({
    component: LinkServiceModal,
    attrs: {
      mappedService,
      excludeCrmServices: mappedServices.value
        .filter(({ uuid }) => uuid !== mappedService.uuid)
        .reduce((uuids, item) => {
          return [...uuids, ...item.crm_services.map(({ uuid }) => uuid)];
        }, [] as string[]),
      async onConfirm(form: ICrmPlannerServiceMapRequestBody, { setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.crm.mappedServices.update(mappedService.uuid, form);
          await getData();
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDeleteMappedService(mappedService: ICrmPlannerServiceMapListResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('service-mapping.confirm.destroy.title'),
      message: t('service-mapping.confirm.destroy.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.crm.mappedServices.destroy(mappedService.uuid);
          await getData();
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEditPlannerService(plannerService: IServicePreviewResource) {
  const { open, close, destroy } = useModal({
    component: CommentModal,
    attrs: {
      initialComment: plannerService.note ?? '',
      required: true,
      title: t('service-mapping.mapping_comment_modal.title'),
      label: t('service-mapping.mapping_comment_modal.note'),
      action: t('common.save'),
      async onSubmit({ setLoading }: ConfirmDialogConfirmParams, notes: string | null) {
        setLoading(true);
        try {
          await api.services.updateComment(plannerService.uuid, { notes });
          plannerService.note = notes ?? '';
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEditCrmService(crmService: ICrmServiceListResource) {
  const { open, close, destroy } = useModal({
    component: CommentModal,
    attrs: {
      initialComment: crmService.note ?? '',
      required: true,
      title: t('service-mapping.mapping_comment_modal.title'),
      label: t('service-mapping.mapping_comment_modal.note'),
      action: t('common.save'),
      async onSubmit({ setLoading }: ConfirmDialogConfirmParams, notes: string) {
        setLoading(true);
        try {
          await api.crm.services.update(crmService.uuid, { notes });
          crmService.note = notes ?? '';
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function getMappedServices() {
  try {
    mappedServicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.crm.mappedServices.list(searchParams);
    mappedServices.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    mappedServicesLoader.finish();
  }
}

async function getServices() {
  try {
    servicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('without_mapping', '1');
    const response = await api.services.index({ searchParams });
    services.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    servicesLoader.finish();
  }
}

async function getCrmServices() {
  try {
    crmServicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('without_mapping', '1');
    const response = await api.crm.services.list(searchParams);
    crmServices.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    crmServicesLoader.finish();
  }
}

async function getData() {
  loader.start();
  await Promise.all([getMappedServices(), getServices(), getCrmServices()]);
  loader.finish();
}

onMounted(getData);
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end justify-content-between mb-2">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="service-mapping.index.mapped_services_help" />
      </div>
      <AppButton
        @click="onCreateMappedService"
        class="ml-auto"
        color="secondary"
        :disabled="mappedServicesLoader.isLoading.value"
      >
        {{ t('service-mapping.index.link_services') }}
      </AppButton>
    </div>
    <div v-if="mappedServicesLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="mappedServices.length === 0">{{ t('common.empty') }}</AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap style="width: 30%">
              {{ t('service-mapping.index.table.services_in_planner') }}
            </AppTableTh>
            <AppTableTh nowrap>{{ t('service-mapping.index.table.services_in_crm') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="mappedService in mappedServices" :key="mappedService.uuid">
            <AppTableTd nowrap>{{ mappedService.service.name }}</AppTableTd>
            <AppTableTd>
              {{ mappedService.crm_services.map((service) => service.name).join(', ') }}
            </AppTableTd>
            <AppTableTd nowrap class="text-right">
              <AppButton
                @click="onEditMappedService(mappedService)"
                v-tooltip.left="t('common.edit')"
                size="small"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>
              <AppButton
                @click="onDeleteMappedService(mappedService)"
                class="ml-2"
                v-tooltip.left="t('common.delete')"
                size="small"
                light
                circle
                color="danger"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>

    <div class="d-flex align-items-end mt-4 mb-2">
      <h1 class="mb-0" v-text="t('service-mapping.index.not_mapped_services_title')" />
      <HelpInformation class="ml-1" translation="service-mapping.index.not_mapped_services_help" />
    </div>
    <div v-if="servicesLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <AppTable v-else hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap style="width: 30%">
            {{ t('service-mapping.index.table.services_in_planner') }}
          </AppTableTh>
          <AppTableTh nowrap>{{ t('service-mapping.index.table.comment') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="service in services" :key="service.uuid">
          <AppTableTd nowrap>{{ service.name }}</AppTableTd>
          <AppTableTd>
            <span v-if="service.note" v-text="service.note" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            <AppButton
              v-tooltip.left="t('common.edit')"
              @click="onEditPlannerService(service)"
              @click.stop=""
              size="small"
              light
              circle
            >
              <FontIcon name="pencil" />
            </AppButton>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
    <div v-if="crmServicesLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <AppTable v-else class="mt-3" hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap style="width: 30%">
            {{ t('service-mapping.index.table.services_in_crm') }}
          </AppTableTh>
          <AppTableTh nowrap>{{ t('service-mapping.index.table.comment') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="crmService in crmServices" :key="crmService.uuid">
          <AppTableTd nowrap>{{ crmService.name }}</AppTableTd>
          <AppTableTd>
            <span v-if="crmService.note" v-text="crmService.note" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            <AppButton
              v-tooltip.left="t('common.edit')"
              @click="onEditCrmService(crmService)"
              size="small"
              light
              circle
            >
              <FontIcon name="pencil" />
            </AppButton>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
  </div>
</template>
