<script setup lang="ts">
import { computed, reactive, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { VueFinalModal, useModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import { AppButton, FormInput, FormLabel, FormTextarea, ContactRoleModal, FontIcon, AppLoader } from '@/components';
import useCountriesStore from '@/store/CountriesStore';
import useContactRolesStore from '@/store/ContactRolesStore';
import { SWEDEN } from '@/constants/countries';
import { ConfirmDialogConfirmParams, SetLoading } from '@/types/Common';
import { ICountry } from '@/types/Country';
import { debounce } from 'perfect-debounce';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import { IContactFullResource, IContactRequestBody, IContactRole, IContactSearchResource } from '@/types/Contact';

const contactRolesStore = useContactRolesStore();
const { addContactRole } = contactRolesStore;
const { contactRoles, contactRolesLoading } = storeToRefs(contactRolesStore);

const { t, locale } = useI18n({ useScope: 'global' });
const countriesStore = useCountriesStore();
const { countries } = storeToRefs(countriesStore);

const roleModal = useModal({
  component: ContactRoleModal,
  attrs: {
    async onCreated(createdRole: IContactRole) {
      if (!contactRoles.value.some((role) => role.uuid === createdRole.uuid)) {
        addContactRole(createdRole);
      }
      if (!form.roles.includes(createdRole.uuid)) {
        form.roles = [...form.roles, createdRole.uuid];
      }
      await roleModal.close();
    },
    onCancel() {
      roleModal.close();
    },
  },
});

const { title, clientCreatedWithCreditSafe, contact } = defineProps<{
  title: string;
  clientCreatedWithCreditSafe: boolean;
  contact: null | IContactFullResource;
}>();

const form = reactive<IContactRequestBody>({
  name: '',
  short_name: '',
  personal_number: '',
  email: '',
  phone: '',
  nationality: SWEDEN,
  comment: '',
  roles: [],
  existing_contact_uuid: null,
});

const searchContactData = reactive<{ term: string; results: IContactSearchResource[]; active: boolean }>({
  term: '',
  results: [],
  active: false,
});

const emit = defineEmits<{
  (e: 'closed'): void;
  (e: 'cancel'): void;
  (e: 'create', form: IContactRequestBody, params: ConfirmDialogConfirmParams): void;
  (e: 'update', uuid: string, form: IContactRequestBody, params: ConfirmDialogConfirmParams): void;
}>();

const loading = ref(false);
const searchLoader = useLoader({ useProgress: false });
const basicInfoLoader = useLoader();

const setLoading: SetLoading = (value: boolean) => {
  loading.value = value;
};

const disabled = computed(() => form.name === '' || form.roles.length === 0);

const sortedRoles = computed(() => {
  if (clientCreatedWithCreditSafe) {
    return contactRoles.value.toSorted((a: IContactRole) => (a.is_available_for_manual_contacts ? -1 : 1));
  }
  return contactRoles.value;
});

const disabledRolesToDeselect = computed(() =>
  contact && clientCreatedWithCreditSafe
    ? contact.roles.filter((role) => !role.is_manually_attached).map(({ uuid }) => uuid)
    : [],
);

function formatPersonalNumberByNationality(personalNumber: string, nationality: null | string) {
  if (!personalNumber) return '';
  switch (nationality) {
    case SWEDEN:
      return personalNumber.toString().replace(/-/g, '');
    default:
      return personalNumber;
  }
}

function submit() {
  const params = {
    ...form,
    personal_number: formatPersonalNumberByNationality(form.personal_number, form.nationality),
  };
  if (contact) {
    emit('update', contact.uuid, params, { setLoading });
  } else {
    emit('create', params, { setLoading });
  }
}

const onSearchInput = debounce(async () => {
  if (searchContactData.term.length > 0) {
    searchLoader.start();
    activateSearch();
    try {
      const response = await api.contacts.search(searchContactData.term);
      searchContactData.results = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      searchLoader.finish();
    }
  } else {
    searchContactData.results = [];
    disableSearch();
  }
}, 300);

function onSearchFocus() {
  if (searchContactData.term !== '') {
    activateSearch();
  }
}

function activateSearch() {
  searchContactData.active = true;
}

function disableSearch() {
  searchContactData.active = false;
}

async function onChooseContactInfo(searchedContact: IContactSearchResource) {
  try {
    basicInfoLoader.start();
    form.name = searchedContact.name;
    form.short_name = searchedContact.short_name ?? '';
    form.personal_number = searchedContact.personal_number ?? '';
    form.email = searchedContact.email ?? '';
    form.phone = searchedContact.phone ?? '';
    form.nationality = searchedContact.nationality;
    form.comment = searchedContact.comment;
    form.existing_contact_uuid = searchedContact.source === 'db' ? searchedContact.uuid : null;
    searchContactData.term = '';
    searchContactData.results = [];
    disableSearch();
  } catch (error) {
    console.error(error);
  } finally {
    basicInfoLoader.finish();
  }
}

onMounted(async () => {
  if (contact) {
    form.name = contact.name;
    form.short_name = contact.short_name ?? '';
    form.personal_number = contact.personal_number ?? '';
    form.email = contact.email ?? '';
    form.phone = contact.phone ?? '';
    form.roles = contact.roles.map((role) => role.uuid);
    form.nationality = contact.nationality;
    form.comment = contact.comment;
  } else {
    form.name = '';
    form.short_name = '';
    form.personal_number = '';
    form.email = '';
    form.phone = '';
    form.roles = [];
    form.nationality = SWEDEN;
    form.comment = '';
  }
});
</script>

<template>
  <VueFinalModal
    @closed="emit('closed')"
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="title" />
      </div>
      <div class="modal-content">
        <div class="row">
          <div v-if="!contact" class="form-group col-lg-12">
            <FormLabel html-for="search_in_db">{{ t('client.contact.search_in_db_or_cs') }}</FormLabel>
            <FormInput
              type="search"
              id="search_in_db"
              icon="search"
              @input="onSearchInput"
              @focus="onSearchFocus"
              v-model="searchContactData.term"
              :disabled="basicInfoLoader.isLoading.value"
              @keydown.enter.prevent
              :placeholder="t('client.contact.search_contacts_placeholder')"
            >
              <template v-if="searchContactData.active" #popup>
                <div v-if="searchLoader.isLoading.value" class="text-center">
                  <AppLoader size="small" />
                </div>
                <ul :class="{ disabled: basicInfoLoader.isLoading.value }" v-else>
                  <li
                    class="text-neutral-500"
                    v-if="searchContactData.results.length === 0"
                    v-text="t('common.no_results')"
                  />
                  <li
                    class="hoverable pointer"
                    v-for="item in searchContactData.results"
                    :key="item.uuid"
                    @click.stop="onChooseContactInfo(item)"
                  >
                    <strong>{{ item.name }}</strong>
                    <span v-if="item.personal_number"
                      >, {{ $t('common.personal_number') }}:
                      <strong>{{ item.personal_number }}</strong>
                    </span>
                    <span v-if="item.email"
                      >, {{ $t('common.email') }}:
                      <strong>{{ item.email }}</strong>
                    </span>
                    <span v-if="item.phone"
                      >, {{ $t('common.phone') }}:
                      <strong>{{ item.phone }}</strong>
                    </span>
                    <span v-if="item.source">
                      &nbsp;[{{ item.source === 'db' ? $t('client.contact.existing') : $t('client.contact.new') }}]
                    </span>
                  </li>
                </ul>
              </template>
            </FormInput>
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="contact_name" required>{{ t('client.contact.name') }}</FormLabel>
            <FormInput
              id="contact_name"
              v-model="form.name"
              required
              :disabled="clientCreatedWithCreditSafe && contact?.is_manual_created === false"
            />
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="short_name">{{ t('client.contact.short_name') }}</FormLabel>
            <FormInput id="short_name" v-model="form.short_name" />
          </div>
          <div class="form-group col-12">
            <FormLabel html-for="company_contact_role" required>{{ t('client.contact.roles') }}</FormLabel>
            <div class="d-flex">
              <VueSelect
                class="flex-grow-1"
                :clearable="false"
                v-model="form.roles"
                :reduce="(option: IContactRole) => option.uuid"
                :options="sortedRoles"
                :get-option-label="(option: IContactRole) => option.name[locale]"
                input-id="company_contact_role"
                :placeholder="t('common.search')"
                required
                :loading="contactRolesLoading"
                :disabled="contactRolesLoading"
                multiple
                :clear-search-on-blur="() => true"
                :selectable="(option: IContactRole) => clientCreatedWithCreditSafe ? option.is_available_for_manual_contacts : true"
                :deselectable="false"
                :close-on-select="false"
              >
                <template #selected-option="option: IContactRole">
                  <span
                    :class="{
                      'role-clean-disabled': disabledRolesToDeselect.includes(option.uuid),
                    }"
                  >
                    {{ option.name[locale] }}
                  </span>
                </template>
              </VueSelect>
              <AppButton
                v-if="false"
                type="button"
                @click.prevent="roleModal.open"
                light
                circle
                class="flex-shrink-0 ml-1"
                v-tooltip="t('contact.role.tooltip.create')"
                :disabled="contactRolesLoading"
              >
                <FontIcon name="plus" />
              </AppButton>
            </div>
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="nationality" required>{{ t('client.contact.nationality') }}</FormLabel>
            <VueSelect
              :reduce="(option: ICountry) => option.code"
              :clearable="false"
              v-model="form.nationality"
              :options="countries"
              :get-option-label="(option: ICountry) => (option.name[locale])"
              input-id="nationality"
              :placeholder="t('common.not_selected')"
              required
            />
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="personal_number">{{ t('client.contact.personal_number') }}</FormLabel>
            <FormInput
              id="personal_number"
              v-model="form.personal_number"
              :disabled="clientCreatedWithCreditSafe && contact?.is_manual_created === false"
            />
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="phone">{{ t('client.contact.phone') }}</FormLabel>
            <FormInput id="phone" v-model="form.phone" />
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="email">{{ t('client.contact.email') }}</FormLabel>
            <FormInput id="email" v-model="form.email" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-lg-12">
            <FormLabel html-for="comment">{{ t('client.contact.comment') }}</FormLabel>
            <FormTextarea id="comment" v-model="form.comment" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">{{ t('common.cancel') }}</AppButton>
          <AppButton v-if="contact" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('client.contact.add') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>

<style lang="scss">
.role-clean-disabled {
  & + .vs__deselect {
    display: none;
  }
}
</style>
