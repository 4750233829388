<script setup lang="ts">
import { ref, reactive, computed, onMounted, inject } from 'vue';
import VueSelect from 'vue-select';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { storeToRefs } from 'pinia';
import { findIndex } from 'lodash';
import { UserStatusType } from '@/types/User';

import useLoader from '@/composables/useLoader';
import {
  AppLoader,
  AppBox,
  AppButton,
  AppBoxBody,
  AppTable,
  AppTableHead,
  AppTableTr,
  AppTableTh,
  AppTableTd,
  AppTableBody,
  FontIcon,
  ConfirmModal,
  FormLabel,
} from '@/components';

import { IProjectTeamMemberListResource, IProjectTeamMemberRequestBody, ProjectStep } from '@/types/Project';
import api from '@/services/api';
import { IUserListResource } from '@/types/User';
import useProjectStore from '@/store/ProjectStore';
import toast from '@/services/toast';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const projectId = inject('projectId') as number;
const clientUuid = inject('clientUuid') as string;

const projectStore = useProjectStore();
const { setProject, lockTabs, unlockTabs } = projectStore;
const { project } = storeToRefs(projectStore);

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader({ useProgress: false });
const addMemberLoader = useLoader({ useProgress: false });
const nextLoader = useLoader({ useProgress: false });
const saveLoader = useLoader({ useProgress: false });

const addingMode = ref(false);
const users = ref<IUserListResource[]>([]);
const usersLoader = useLoader({ useProgress: false });
const filteredUsers = computed(() => {
  return users.value.filter(
    (user) => !teamMembers.value.some(({ uuid, is_active }) => uuid === user.uuid && is_active),
  );
});

const emit = defineEmits<{
  (e: 'change-step', step: ProjectStep): void;
}>();

const submitMode = ref<'back' | 'update' | 'next'>('next');

const teamMembers = ref<IProjectTeamMemberListResource[]>([]);

const formMemberAdd = reactive<IProjectTeamMemberRequestBody>({
  user_uuid: '',
});

async function submit() {
  lockTabs();
  if (submitMode.value === 'update') saveLoader.start();
  else if (submitMode.value === 'next') nextLoader.start();
  try {
    const response = await api.projects.update(
      {
        step: ProjectStep.Team,
        team_members: teamMembers.value.map(({ uuid: user_uuid, role }) => ({ user_uuid, role })),
      },
      clientUuid,
      projectId,
    );
    setProject(response.data);
    if (submitMode.value === 'next') {
      emit('change-step', ProjectStep.Planning);
    }
    if (submitMode.value === 'update') {
      toast.success(t('common.messages.has_been_updated', { name: t('common.project') }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    nextLoader.finish();
    saveLoader.finish();
    unlockTabs();
  }
}

async function getTeamMembers() {
  try {
    const response = await api.projects.teamMember.list(clientUuid, projectId);
    teamMembers.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getUsers() {
  try {
    usersLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', UserStatusType.Active);
    searchParams.append('statuses[]', UserStatusType.Invited);
    const response = await api.users.list({ searchParams });
    users.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    usersLoader.finish();
  }
}

async function getProject() {
  try {
    const response = await api.projects.get(clientUuid, projectId);
    setProject(response.data);
  } catch (error) {
    console.error(error);
  }
}

async function addMember() {
  try {
    addMemberLoader.start();
    const response = await api.projects.teamMember.add(clientUuid, projectId, formMemberAdd);
    const possibleMember = teamMembers.value.find(({ uuid }) => formMemberAdd.user_uuid === uuid);
    if (possibleMember) {
      possibleMember.is_active = true;
    } else {
      teamMembers.value.push({
        name: response.data.name,
        uuid: response.data.uuid,
        role: response.data.role,
        is_active: true,
      });
    }

    formMemberAdd.user_uuid = '';
    addingMode.value = false;
  } catch (error) {
    console.error(error);
  } finally {
    addMemberLoader.finish();
    getProject();
  }
}

function onDelete({ uuid, name, role }: IProjectTeamMemberListResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('user.confirm.destroy.title'),
      message: t('user.confirm.destroy.text', { name }),
      params: { name, role },
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.projects.teamMember.destroy(clientUuid, projectId, uuid);
          await Promise.all([getTeamMembers(), getProject()]);
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onRoleChange({ role, uuid }: IProjectTeamMemberListResource) {
  if (role === 'responsible') {
    teamMembers.value.forEach((otherMember) => {
      if (otherMember.uuid !== uuid) {
        otherMember.role = 'team_member';
      }
    });
  } else if (role === 'team_member' && teamMembers.value.some((otherMember) => otherMember.role !== 'responsible')) {
    const memberIndex = findIndex(teamMembers.value, ['user_uuid', uuid]);
    teamMembers.value[memberIndex === 0 ? 1 : 0].role = 'responsible';
  }
}

onMounted(async () => {
  lockTabs();
  loader.start();
  if (project.value) {
    await Promise.all([getUsers(), getTeamMembers()]);
  }
  loader.finish();
  unlockTabs();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit">
    <AppBox class="mb-4" shadow>
      <AppBoxBody>
        <div class="row">
          <div class="col-lg-10 col-xl-8">
            <h2 v-text="t('project.team_members')" />
            <AppTable hoverable style="overflow: visible; box-shadow: none">
              <AppTableHead>
                <AppTableTr>
                  <AppTableTh nowrap>{{ t('user.attributes.name') }}</AppTableTh>
                  <AppTableTh nowrap>{{ t('project.project_role') }}</AppTableTh>
                  <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
                </AppTableTr>
              </AppTableHead>
              <AppTableBody>
                <AppTableTr v-for="member in teamMembers" :key="member.uuid">
                  <AppTableTd nowrap>
                    <strong v-if="member.is_active" v-text="member.name" />
                    <span class="text-neutral-400" v-else>{{ member.name }} ({{ t('common.deleted') }})</span>
                  </AppTableTd>
                  <AppTableTd nowrap>
                    <div v-if="member.is_active" class="form-wrapper is-small">
                      <select class="form-control" v-model="member.role" required @change="onRoleChange(member)">
                        <option value="" hidden disabled v-text="t('common.select')" />
                        <option value="responsible" v-text="t('project.team_member_role.responsible')" />
                        <option
                          value="team_member"
                          :disabled="teamMembers.length === 1"
                          v-text="t('project.team_member_role.team_member')"
                        />
                      </select>
                    </div>
                    <span class="text-neutral-400" v-else>{{ t(`project.team_member_role.${member.role}`) }} </span>
                  </AppTableTd>
                  <AppTableTd nowrap class="text-right">
                    <AppButton
                      v-tooltip="t('user.tooltip.destroy', { name: member.name })"
                      @click.stop.prevent="onDelete(member)"
                      class="ml-2"
                      color="danger"
                      size="small"
                      light
                      circle
                      v-if="project?.user.uuid !== member.uuid && member.is_active"
                    >
                      <FontIcon name="trash" />
                    </AppButton>
                  </AppTableTd>
                </AppTableTr>
                <AppTableTr v-if="addingMode">
                  <AppTableTd>
                    <FormLabel html-for="new-team-member">
                      {{ t('project.add_member_title') }}
                    </FormLabel>
                    <VueSelect
                      id="new-team-member"
                      :clearable="false"
                      v-model="formMemberAdd.user_uuid"
                      :reduce="(option: any) => option.uuid"
                      :options="filteredUsers"
                      label="name"
                      :placeholder="t('common.search')"
                      :disabled="addMemberLoader.isLoading.value || usersLoader.isLoading.value"
                      :loading="usersLoader.isLoading.value"
                      @update:model-value="addMember"
                    />
                  </AppTableTd>
                  <AppTableTd />
                  <AppTableTd />
                </AppTableTr>
              </AppTableBody>
            </AppTable>
            <AppButton
              class="mt-3"
              :color="addingMode ? 'danger' : 'success'"
              @click.prevent="addingMode = !addingMode"
              circle
              light
              :loading="addMemberLoader.isLoading.value"
              :disabled="usersLoader.isLoading.value"
              v-tooltip.left="addingMode ? t('common.cancel') : t('common.add')"
            >
              <FontIcon :name="addingMode ? 'x' : 'plus'" />
            </AppButton>
          </div>
        </div>
      </AppBoxBody>
    </AppBox>
    <div class="mt-3 d-flex flex-nowrap">
      <AppButton
        @click.prevent="emit('change-step', ProjectStep.Price)"
        class="mr-2"
        light
        :disabled="saveLoader.isLoading.value || nextLoader.isLoading.value"
      >
        <FontIcon name="chevron-left" />
        {{ t('common.back') }}
      </AppButton>
      <AppButton
        @click="submitMode = 'update'"
        class="mr-2 ml-auto"
        color="success"
        :loading="saveLoader.isLoading.value"
        :disabled="
          nextLoader.isLoading.value || (addingMode && !formMemberAdd.user_uuid) || addMemberLoader.isLoading.value
        "
      >
        <FontIcon name="device-floppy" />
        {{ t('common.update') }}
      </AppButton>
      <AppButton
        @click="submitMode = 'next'"
        color="secondary"
        :loading="nextLoader.isLoading.value"
        :disabled="
          saveLoader.isLoading.value || (addingMode && !formMemberAdd.user_uuid) || addMemberLoader.isLoading.value
        "
      >
        {{ t('common.next') }}
        <FontIcon name="chevron-right" />
      </AppButton>
    </div>
  </form>
</template>
