import { RepetitionFrequencyType } from '@/types/Common';
import { IUserPreviewResource } from '@/types/User';
import { ISalaryCodeResource } from '@/types/Salary';
import { IEventTrackedTimeRequest } from '@/types/Event';

export enum ActivityGroupType {
  ABSENCE = 'absence',
  INTERNAL_WORK = 'internal_work',
}

export enum ActivityCommentType {
  FREE_TEXT = 'free_text',
  DROPDOWN = 'dropdown',
}

export enum MetaInputType {
  INPUT_TEXT = 'input_text',
}

export interface IActivityGroupResource {
  id: number;
  name: string;
  type: ActivityGroupType;
}

export type IActivityGroupListResource = IActivityGroupResource & {
  templates_count: number;
};

export type IActivityGroupRequestBody = {
  [K in keyof IActivityGroupResource as K extends 'name' | 'type' ? K : never]: IActivityGroupResource[K] | null;
};

export enum ActivityDurationRule {
  AVAILABLE_ALL_DAY = 'available_all_day',
  AVAILABLE_TIME_PERCENTAGE_RANGE = 'available_time_percentage_range',
  ONLY_ALL_DAY = 'only_all_day',
}

export interface IMetaActivityResource {
  id: string;
  value: string;
}

export interface IMetaInputResource {
  id: string;
  label: string;
  is_required: boolean;
  type: MetaInputType.INPUT_TEXT;
}

export interface ICommentOptionResource {
  id: string;
  option: string;
}

export interface IActivityTemplateMinimizeResource {
  id: number;
  name: string;
}

export interface IActivityTemplateListResource {
  id: number;
  name: string;
  activity_group: IActivityGroupResource;
  activity_type: ActivityGroupType;
  duration_rule: null | ActivityDurationRule;
  meta_input: IMetaInputResource[];
  default_frequency: null | RepetitionFrequencyType;
}

export interface IActivityTemplateResource {
  id: number;
  name: string;
  comment_type: ActivityCommentType;
  comment_options: ICommentOptionResource[];
  activity_group: IActivityGroupResource;
  activity_type: ActivityGroupType;
  duration_rule: null | ActivityDurationRule;
  meta_input: IMetaInputResource[];
  include_in_planning: boolean;
  order: number;
  default_frequency: null | RepetitionFrequencyType;
  salary_code: null | Pick<ISalaryCodeResource, 'id' | 'name'>;
}

export interface IActivityTemplateRequest {
  name: string;
  duration_rule: null | ActivityDurationRule;
  meta_input: IMetaInputResource[];
  comment_type: ActivityCommentType;
  comment_options: ICommentOptionResource[];
  include_in_planning: boolean;
  default_frequency: null | RepetitionFrequencyType;
  salary_code_id: null | number;
}

export interface IActivityListResource {
  id: number;
  template: Pick<IActivityTemplateResource, 'id' | 'name'>;
  start_week: number;
  frequency: null | RepetitionFrequencyType;
  users: IUserPreviewResource[];
  scheduled_time: number;
  order: number;
  note: null | string;
}

export interface IActivityMetaRequest {
  id: string;
  value: string;
  is_required: boolean;
  label: string;
  type: MetaInputType;
}

export interface ICreateActivityRequest {
  start_date: null | string;
  end_date: null | string;
  activity_template_id: null | number;
  internal_project_id: null | number;
  user_uuid: null | string;
  frequency: null | RepetitionFrequencyType;
  meta: IActivityMetaRequest[];
  tracked_time: IEventTrackedTimeRequest[];
  is_done: boolean;
  scheduled_time: number;
  note: null | string;
  mileage_allowance: null | {
    vehicle_id: null | number;
    mileage: number;
    amount: number;
  };
}

export interface ActivityModalProps {
  type?: 'absence' | 'internal';
  initialWeek?: number;
  id?: number;
  addTrackedTimeOnOpen?: { date: string; time: number };
}

export interface IActivityResource {
  id: number;
  name: string;
  comment: string | null;
  start_date: string;
  end_date: string | null;
  template: IActivityTemplateResource;
  meta: IMetaActivityResource[];
  internal_project: null | {
    id: number;
    name: string;
  };
}
