<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ClassValue, clsx } from 'clsx';
import api from '@/services/api';
import { AppAlert, AppBox, AppBoxBody, AppButton, AppLoader, FontIcon, FormLabel, FormSwitch } from '@/components';
import useDate from '@/composables/useDate';
import { IUserDeadlineEventResource } from '@/types/User';
import { IEventAffectedWeeks } from '@/types/Event';
import useTimeReportModal from '@/composables/useTimeReportModal';

type Props = {
  userUuid: string;
  weekNumber: number;
  parentClass?: ClassValue;
};

type Emit = {
  updated: [args: IEventAffectedWeeks];
};

const { userUuid, weekNumber, parentClass } = defineProps<Props>();
const emit = defineEmits<Emit>();
defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n({ useScope: 'global' });
const { formatYearWeek } = useDate();
const { openProjectTaskEditModal } = useTimeReportModal();

const deadlines = ref<IUserDeadlineEventResource[]>([]);
const unfinishedTasks = ref(false);
const loading = ref(false);

async function getDeadlines() {
  try {
    loading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('week', weekNumber.toString());
    searchParams.append('unfinished_tasks', Number(!unfinishedTasks.value).toString());
    const response = await api.users.events.deadlines(userUuid, { searchParams });
    deadlines.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

function onProjectTaskEdit(id: number) {
  openProjectTaskEditModal(
    { id },
    {
      onUpdated({ affectedWeeks, close }) {
        getDeadlines();
        emit('updated', { affectedWeeks });
        close();
      },
      onSplit({ affectedWeeks, close }) {
        getDeadlines();
        emit('updated', { affectedWeeks });
        close();
      },
      onDeleted({ affectedWeeks, close }) {
        getDeadlines();
        emit('updated', { affectedWeeks });
        close();
      },
    },
  );
}

onMounted(getDeadlines);

defineExpose({
  getDeadlines,
});

watch(unfinishedTasks, getDeadlines);
</script>

<template>
  <AppBox shadow :class="clsx(parentClass)">
    <AppBoxBody>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0 flex-grow-1" v-text="t('user.deadlines.title', { weekNumber: formatYearWeek(weekNumber) })" />
        <RouterLink
          custom
          :to="{ name: 'deadlines.index', query: { user_uuid: userUuid } }"
          v-slot="{ href, navigate }"
        >
          <a @click="navigate" :href="href" target="_blank">
            <AppButton v-tooltip.left="t('navigation.deadlines.index')" class="flex-shrink-0" light circle>
              <FontIcon name="external-link" />
            </AppButton>
          </a>
        </RouterLink>
      </div>
      <AppAlert class="mt-3" v-if="!loading && deadlines.length === 0">{{ t('event.empty') }}</AppAlert>
    </AppBoxBody>
    <div v-if="loading" class="text-center">
      <AppLoader size="small" />
    </div>
    <PerfectScrollbar v-else class="user-deadlines" :options="{ wheelPropagation: false }">
      <div
        v-for="deadline in deadlines"
        class="box-body"
        style="border-top: 1px solid var(--color-neutral-200-hex)"
        :key="deadline.client_uuid"
      >
        <div class="project" v-for="project in deadline.projects" :key="project.id">
          <h4>
            <RouterLink
              custom
              :to="{ name: 'client.overview', params: { uuid: deadline.client_uuid } }"
              v-slot="{ href, navigate }"
            >
              <a class="font-bold text-neutral-900" @click="navigate" :href="href" target="_blank">
                {{ deadline.client_name }}
              </a>
            </RouterLink>
            -
            <RouterLink
              custom
              :to="{ name: 'projects.view', params: { uuid: deadline.client_uuid, id: project.id } }"
              v-slot="{ href, navigate }"
            >
              <a class="font-bold text-neutral-900" @click="navigate" :href="href" target="_blank">
                {{ project.name }}
              </a>
            </RouterLink>
          </h4>
          <div class="task" @click="onProjectTaskEdit(task.id)" v-for="task in project.tasks" :key="task.id">
            <FontIcon v-if="task.done_at" class="text-success-500" name="check" />
            {{ task.name }}
            <template v-if="task.sub_label"> - {{ task.sub_label }} </template>
          </div>
        </div>
      </div>
    </PerfectScrollbar>
    <AppBoxBody>
      <div class="d-flex align-items-start">
        <FormLabel html-for="unfinished_tasks">{{ t('user.deadlines.show_completed') }}</FormLabel>
        <FormSwitch group-class="mb-0" id="unfinished_tasks" v-model="unfinishedTasks" :disabled="loading" />
      </div>
    </AppBoxBody>
  </AppBox>
</template>
