import { IActivityGroupResource, IActivityListResource } from '@/types/Activity';
import { RepetitionFrequencyType } from '@/types/Common';

export enum InternalProjectStatusType {
  DRAFT = 'draft',
  ACTIVE = 'active',
  CLOSED = 'closed',
}

export interface IInternalProjectResource {
  id: number;
  name: string;
  start_date: string;
  end_date: null | string;
  status: InternalProjectStatusType;
  activity_group: Pick<IActivityGroupResource, 'id' | 'name'>;
  activities: IActivityListResource[];
}

export interface IInternalProjectActivityCreateOrUpdateRequestBody {
  id?: number;
  template_id: null | number;
  frequency: null | RepetitionFrequencyType;
  start_week: null | number;
  users_id: string[];
  scheduled_time: null | number;
  note: null | string;
  order: number;
}

export interface IInternalProjectCreateOrUpdateRequestBody {
  start_date: string;
  end_date: null | string;
  name: null | string;
  activity_group_id: null | number;
  activities: IInternalProjectActivityCreateOrUpdateRequestBody[];
}
