<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import api from '@/services/api';
import {
  AppAlert,
  AppAvatar,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  ContactsTable,
  FontIcon,
  FormLabel,
  FormTextarea,
  ProjectStatus,
  UserTodos,
} from '@/components';
import { ClientType, IClientCreditInfo, IClientUpdateInformationRequestBody } from '@/types/Client';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useTime from '@/composables/useTime';
import { downloadFile } from '@/utils/file-downloader';
import { IProjectWithTeamsResource, ProjectStatusType } from '@/types/Project';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { storeToRefs } from 'pinia';
import useClientStore from '@/store/ClientStore';
import { IContactFullResource } from '@/types/Contact';
import useProject from '@/composables/useProject';
import useAuthStore from '@/store/AuthStore';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const { getCurrentDate } = useDate();
const { t, d, n } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();
const { openDeleteProjectModal, openCancelOrRolloverProjectModal } = useProject();
const submitLoader = useLoader();
const { authenticatedUser } = useAuthStore();

// Client data
const { client } = storeToRefs(useClientStore());

const form = reactive<IClientUpdateInformationRequestBody>({
  other_information: '',
});

// client credit data
const clientCreditDataLoader = useLoader({ useProgress: false });
const clientCreditData = ref<null | IClientCreditInfo>(null);

// Contacts data
const contactsElement = ref<HTMLDivElement | null>(null);
const contactsLoader = useLoader({ useProgress: false });
const currentContactsPage = useRouteQuery('overview-contact-page', '1', { transform: Number });
const contactsPerPage = ref(20);
const contactsTotal = ref(0);
const clientContacts = ref<IContactFullResource[]>([]);

// Projects & services data
const projects = ref<IProjectWithTeamsResource[]>([]);
const projectsLoader = useLoader({ useProgress: false });

async function getCreditClientInfo() {
  try {
    clientCreditDataLoader.start();
    const response = await api.clients.info.credit(uuid);

    clientCreditData.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    clientCreditDataLoader.finish();
  }
}

async function submit() {
  try {
    submitLoader.start();
    await api.clients.update(form, uuid);
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function getContacts() {
  try {
    contactsLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentContactsPage.value.toString());
    searchParams.append('with_phone_or_email', '1');
    const response = await api.clients.contacts.list(uuid, { searchParams });
    clientContacts.value = response.data;
    contactsPerPage.value = response.meta!.per_page;
    contactsTotal.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    contactsLoader.finish();
  }
}

async function getProjects() {
  try {
    projectsLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', 'active');
    searchParams.append('statuses[]', 'draft');
    const response = await api.projects.listWithFullResource(uuid, searchParams);
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectsLoader.finish();
  }
}

async function onDownloadCreditReport(event: PointerEvent) {
  const showConfirm =
    client.value.company_category.type === ClientType.INDIVIDUAL ||
    (client.value.company_category.type === ClientType.COMPANY &&
      client.value.company_category.name === 'Individual company');

  if (showConfirm) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('client.confirm.report_download.title'),
        message: t('client.confirm.report_download.text'),
        async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            await downloadCreditReport();
            await close();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  } else {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');

    try {
      await downloadCreditReport();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function downloadCreditReport() {
  const response = await api.clients.getClientCreditReportDownloadLink(uuid);
  downloadFile(response.data.url);
}

function compareDeadlineDateWithToday(date: null | string) {
  if (date) {
    const today = getCurrentDate();
    const deadline = new Date(date);
    const current = new Date(today);

    if (deadline <= current) {
      return true;
    }
  }

  return false;
}

function onCancelOrRollover(type: 'cancel' | 'rollover', project: IProjectWithTeamsResource) {
  openCancelOrRolloverProjectModal(
    type,
    {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
      service: project.service,
    },
    uuid,
    {
      onDone() {
        getProjects();
      },
    },
  );
}

function deleteProject(project: IProjectWithTeamsResource) {
  openDeleteProjectModal({ id: project.id, name: project.name }, uuid, {
    onDone() {
      getProjects();
    },
  });
}

onMounted(async () => {
  form.other_information = client.value.other_information ?? '';
  await Promise.all([getContacts(), getProjects(), getCreditClientInfo()]);
});

watch(currentContactsPage, async () => {
  await getContacts();
  contactsElement.value!.scrollIntoView({ behavior: 'smooth', block: 'start' });
});
</script>

<template>
  <!-- Contacts -->
  <div ref="contactsElement">
    <ContactsTable
      :title="t('client.contact.title')"
      :client-uuid="uuid"
      :client-created-with-credit-safe="client.created_with_credit_safe"
      :contacts="clientContacts"
      :loading="contactsLoader.isLoading.value"
      :columns="['name', 'email', 'phone', 'roles', 'portal_status']"
      :setting-use-we-connect="client.settings.use_we_connect"
      :total="contactsTotal"
      :per-page="contactsPerPage"
      v-model:page="currentContactsPage"
    >
      <template #buttons>
        <RouterLink custom :to="{ name: 'client.settings', params: { uuid } }" v-slot="{ href, navigate }">
          <a @click="navigate" :href="href" target="_blank">
            <AppButton color="secondary">
              <FontIcon name="users" />
              {{ t('client.overview.show_all_contacts') }}
            </AppButton>
          </a>
        </RouterLink>
      </template>
    </ContactsTable>
  </div>
  <!-- Projects -->
  <div class="mt-3">
    <h2 v-text="t('project.index.title')" />
    <AppBox shadow v-if="projectsLoader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <template v-else>
      <AppAlert v-if="projects.length === 0">
        {{ t('project.empty') }}
      </AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('project.attributes.name') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.attributes.status') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.attributes.start_end_date') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('client.project.attributes.deadline') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.step.price') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.step.team') }}</AppTableTh>
            <AppTableTh nowrap>
              <span
                v-tooltip.top="
                  `${t('client.attributes.reported')} | ${t('client.attributes.estimated')}
                    | ${t('client.attributes.planned')}`
                "
                nowrap
              >
                {{ t('client.attributes.r') }} | {{ t('client.attributes.e') }} | {{ t('client.attributes.p') }}
              </span>
            </AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('client.project.attributes.margin') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('client.project.attributes.margin_percent') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.attributes.tasks_in_portal') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="project in projects" :key="project.id">
            <AppTableTd>
              <RouterLink
                custom
                :to="{ name: 'projects.edit', params: { uuid, id: project.id } }"
                v-slot="{ href, navigate }"
              >
                <a class="text-neutral-900 font-bold" @click="navigate" :href="href" target="_blank">
                  {{ project.name }}
                </a>
              </RouterLink>
            </AppTableTd>
            <AppTableTd nowrap>
              <ProjectStatus :status="project.status" />
            </AppTableTd>
            <AppTableTd nowrap> {{ d(project.start_date) }} - {{ d(project.end_date) }} </AppTableTd>
            <AppTableTd :class="compareDeadlineDateWithToday(project.deadline_date) ? 'text-danger' : ''" nowrap>
              <span v-if="project.deadline_date" v-text="d(project.deadline_date)" />
              <span v-else v-text="t('common.none')" />
            </AppTableTd>
            <AppTableTd nowrap>
              <span v-if="project.annual_price">
                {{ t(`service.price_type.${project.price_type}`) }}
                <br />
                {{ n(project.annual_price, 'currency') }}
              </span>
              <i v-else class="text-neutral-300" v-text="t('common.empty')" />
            </AppTableTd>
            <AppTableTd nowrap>
              <div class="d-flex gap-1">
                <AppAvatar
                  :name="user.name"
                  v-for="user in project.teams"
                  :key="user.uuid"
                  v-tooltip="user.name"
                  size="small"
                />
              </div>
            </AppTableTd>
            <AppTableTd nowrap>
              {{ convertMinutesToTime(project.total_reported_time) }}
              | {{ convertMinutesToTime(project.total_forecasted_time) }} |
              {{ convertMinutesToTime(project.total_estimated_time) }}
            </AppTableTd>
            <AppTableTd nowrap class="text-right">{{ Math.floor(project.marginality) }}</AppTableTd>
            <AppTableTd nowrap class="text-right">{{ Math.floor(project.marginality_percent) }}%</AppTableTd>
            <AppTableTd nowrap>{{ t('client.overview.n_a') }}</AppTableTd>
            <AppTableTd class="text-right" nowrap>
              <RouterLink
                custom
                :to="{ name: 'projects.view', params: { uuid, id: project.id } }"
                v-slot="{ href, navigate }"
              >
                <a @click="navigate" :href="href" target="_blank">
                  <AppButton
                    v-tooltip.left="t('project.tooltip.view', { name: project.name })"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="eye" />
                  </AppButton>
                </a>
              </RouterLink>
              <RouterLink
                custom
                :to="{ name: 'projects.edit', params: { uuid, id: project.id } }"
                v-slot="{ href, navigate }"
              >
                <a @click="navigate" :href="href" target="_blank">
                  <AppButton
                    v-tooltip.left="t('project.tooltip.plan', { name: project.name })"
                    size="small"
                    light
                    circle
                    class="ml-2"
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                </a>
              </RouterLink>
              <AppButton
                v-if="project.status === ProjectStatusType.Active"
                v-tooltip.left="t('project.tooltip.cancel', { name: project.name })"
                @click.stop="onCancelOrRollover('cancel', project)"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
              >
                <FontIcon name="ban" />
              </AppButton>
              <AppButton
                v-if="project.status === ProjectStatusType.Active"
                v-tooltip.left="t('project.tooltip.rollover', { name: project.name })"
                @click.stop="onCancelOrRollover('rollover', project)"
                class="ml-2"
                color="secondary"
                size="small"
                light
                circle
              >
                <FontIcon name="reload" />
              </AppButton>
              <AppButton
                v-tooltip.left="t('project.tooltip.destroy', { name: project.name })"
                @click.stop="deleteProject(project)"
                class="ml-2"
                size="small"
                color="danger"
                light
                circle
                v-if="project.status === ProjectStatusType.Draft"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>
  </div>
  <div class="row mt-3">
    <div class="col-md-6">
      <!-- Form -->
      <AppBox shadow>
        <AppBoxBody>
          <form @submit.prevent="submit">
            <FormLabel html-for="other_information">{{ t('client.attributes.other_info') }}</FormLabel>
            <FormTextarea id="other_information" class="form-control" size="large" v-model="form.other_information" />
            <AppButton class="mt-3" color="success" :loading="submitLoader.isLoading.value">
              {{ t('common.save') }}
            </AppButton>
          </form>
        </AppBoxBody>
      </AppBox>
      <!-- Invoice and credit info -->
      <div class="mt-3">
        <AppBox shadow v-if="clientCreditDataLoader.isLoading.value" class="text-center py-3">
          <AppLoader />
        </AppBox>
        <AppBox v-else shadow>
          <AppBoxBody>
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h2 class="mb-0" v-text="t('client.overview.invoice_credit_info')" />
              <AppButton light size="small" @click="onDownloadCreditReport">
                <FontIcon name="download" />
                {{ t('client.overview.download_credit_report') }}
              </AppButton>
            </div>
            <AppTable hoverable bordered>
              <AppTableBody>
                <AppTableTr>
                  <AppTableTd style="width: 33%">{{ t('client.overview.due') }}</AppTableTd>
                  <AppTableTd>{{ t('client.overview.n_a') }}</AppTableTd>
                </AppTableTr>
                <AppTableTr v-if="clientCreditData && (clientCreditData.rating || clientCreditData.rating_text)">
                  <AppTableTd>{{ t('client.overview.credit_rating') }}</AppTableTd>
                  <AppTableTd>{{ clientCreditData.rating }} | {{ clientCreditData.rating_text }}</AppTableTd>
                </AppTableTr>
                <AppTableTr v-if="clientCreditData && clientCreditData.kf_debt">
                  <AppTableTd>{{ t('client.overview.skulsaldo') }}</AppTableTd>
                  <AppTableTd>{{ clientCreditData.kf_debt }}</AppTableTd>
                </AppTableTr>
                <AppTableTr v-if="clientCreditData && (clientCreditData.antal_anm || clientCreditData.sum_anm)">
                  <AppTableTd>{{ t('client.overview.payment_remarks') }}</AppTableTd>
                  <AppTableTd>{{ clientCreditData.antal_anm }} | {{ clientCreditData.sum_anm }}</AppTableTd>
                </AppTableTr>
              </AppTableBody>
            </AppTable>
          </AppBoxBody>
        </AppBox>
      </div>
      <!-- Registered information -->
      <div class="mt-3" v-if="clientCreditData">
        <AppBox shadow v-if="clientCreditDataLoader.isLoading.value" class="text-center py-3">
          <AppLoader />
        </AppBox>
        <AppBox v-else shadow>
          <AppBoxBody>
            <h2 class="mb-4" v-text="t('client.overview.registered_information')" />
            <div>
              <h4>{{ t('client.overview.general_information') }}</h4>
              <AppTable hoverable bordered>
                <AppTableBody>
                  <AppTableTr>
                    <AppTableTd style="width: 33%">{{ t('client.overview.status') }}</AppTableTd>
                    <AppTableTd>{{ clientCreditData.status ?? t('common.none') }}</AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd>{{ t('client.overview.incorporation_date') }}</AppTableTd>
                    <AppTableTd>
                      {{
                        clientCreditData.registration_date
                          ? d(
                              clientCreditData.registration_date.substring(0, 4) +
                                '-' +
                                clientCreditData.registration_date.substring(4, 6) +
                                '-' +
                                clientCreditData.registration_date.substring(6, 8),
                            )
                          : t('common.none')
                      }}
                    </AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd>{{ t('client.overview.sni') }}</AppTableTd>
                    <AppTableTd>
                      <span v-if="clientCreditData.industry || clientCreditData.industry_text">
                        {{ clientCreditData.industry }} -
                        {{ clientCreditData.industry_text }}
                      </span>
                      <span v-else>
                        {{ t('common.none') }}
                      </span>
                    </AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd>{{ t('client.overview.business_description') }}</AppTableTd>
                    <AppTableTd>
                      {{ clientCreditData.activity_text ? clientCreditData.activity_text : t('common.none') }}
                    </AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd>{{ t('client.overview.share_capital') }}</AppTableTd>
                    <AppTableTd>
                      {{ clientCreditData.share_capital ? clientCreditData.share_capital : t('common.none') }}
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>

              <!-- Tax registration-->
              <h4 class="mt-3">{{ t('client.overview.tax_registration') }}</h4>
              <AppTable hoverable bordered>
                <AppTableHead>
                  <AppTableTr>
                    <AppTableTh style="width: 33%">{{ t('client.overview.tax') }}</AppTableTh>
                    <AppTableTh style="width: 33%">{{ t('client.overview.active') }}</AppTableTh>
                    <AppTableTh style="width: 33%">{{ t('client.overview.reg_date') }}</AppTableTh>
                  </AppTableTr>
                </AppTableHead>
                <AppTableBody>
                  <AppTableTr>
                    <AppTableTd>{{ t('client.overview.f_tax') }}</AppTableTd>
                    <AppTableTd>
                      <span v-if="clientCreditData.f_tax" v-text="t('client.overview.yes')" />
                      <span v-else v-text="t('client.overview.no')" />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="clientCreditData.f_tax_start_date" v-text="d(clientCreditData.f_tax_start_date)" />
                      <span v-else>{{ t('common.none') }}</span>
                    </AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd nowrap>{{ t('client.overview.vat') }}</AppTableTd>
                    <AppTableTd nowrap>
                      <span v-if="clientCreditData.vat" v-text="t('client.overview.yes')" />
                      <span v-else v-text="t('client.overview.no')" />
                    </AppTableTd>
                    <AppTableTd nowrap>
                      <span v-if="clientCreditData.vat_start_date" v-text="d(clientCreditData.vat_start_date)" />
                      <span v-else>{{ t('common.none') }}</span>
                    </AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd nowrap>{{ t('client.overview.employer') }}</AppTableTd>
                    <AppTableTd nowrap>
                      <span v-if="clientCreditData.emp_tax" v-text="t('client.overview.yes')" />
                      <span v-else v-text="t('client.overview.no')" />
                    </AppTableTd>
                    <AppTableTd nowrap>{{ t('common.none') }}</AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>

              <!-- Group information -->
              <h4 class="mt-3">{{ t('client.overview.group_information') }}</h4>
              <AppTable hoverable bordered>
                <AppTableBody>
                  <AppTableTr>
                    <AppTableTd style="width: 33%">{{ t('client.overview.parent_company') }}</AppTableTd>
                    <AppTableTd>
                      <span
                        v-if="
                          clientCreditData.immediate_group_mother_name || clientCreditData.immediate_group_org_number
                        "
                      >
                        {{
                          clientCreditData.immediate_group_mother_name
                            ? clientCreditData.immediate_group_mother_name + ' | '
                            : ''
                        }}
                        {{ clientCreditData.immediate_group_org_number }}
                      </span>
                      <span v-else>{{ t('common.none') }}</span>
                    </AppTableTd>
                  </AppTableTr>
                  <AppTableTr>
                    <AppTableTd>{{ t('client.overview.group_parent_company') }}</AppTableTd>
                    <AppTableTd>
                      <span
                        v-if="
                          clientCreditData.group_mother_name ||
                          clientCreditData.group_mother_org_number ||
                          clientCreditData.group_mother_country_code
                        "
                      >
                        {{ clientCreditData.group_mother_name ? clientCreditData.group_mother_name + ' | ' : '' }}
                        {{
                          clientCreditData.group_mother_org_number
                            ? clientCreditData.group_mother_org_number + ' | '
                            : ''
                        }}
                        {{ clientCreditData.group_mother_country_code }}
                      </span>
                      <span v-else>{{ t('common.none') }}</span>
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>

              <!-- Signing authority -->
              <h4 class="mt-3">{{ t('client.overview.signing_authority') }}</h4>
              <AppTable bordered hoverable>
                <AppTableBody>
                  <AppTableTr>
                    <AppTableTd style="width: 33%">{{ t('client.overview.signing_authority') }}</AppTableTd>
                    <AppTableTd>
                      {{ clientCreditData.company_sign ? clientCreditData.company_sign : t('common.none') }}
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>
            </div>
          </AppBoxBody>
        </AppBox>
      </div>
    </div>
    <div class="col-md-6">
      <UserTodos
        type="todos"
        :title="t('user.todos.todos.title')"
        :user-uuid="authenticatedUser.uuid"
        :customer="{ uuid, name: client.name }"
        :filter="(todos) => todos.filter((todo) => todo.clientUuid === uuid)"
      />
      <div class="mt-3">
        <UserTodos
          type="infos"
          :title="t('user.todos.informations.title')"
          :user-uuid="authenticatedUser.uuid"
          :customer="{ uuid, name: client.name }"
          :filter="(todos) => todos.filter((todo) => todo.clientUuid === uuid)"
        />
      </div>
    </div>
  </div>

  <div class="mt-5"></div>
</template>
