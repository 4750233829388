import { IUserPreviewResource, PortalUserStatusType } from '@/types/User';
import {
  IInvoiceItemPostponeResource,
  InvoiceDistributionType,
  InvoiceExternalStatusType,
  InvoiceInternalStatusType,
} from '@/types/Invoice';
import { IProjectBillingPlanResource, IProjectCheckpointListResource, IProjectPreviewResource } from '@/types/Project';
import { IPlanningEventResource } from '@/types/ProjectTask';
import { IServiceCompanyContactPreviewResource, IServiceCompanyListResource } from '@/types/ServiceCompany';
import { IServicePreviewResource } from '@/types/Service';
import { IOfficeResource } from '@/types/Office';

export enum ClientType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
}

export enum ClientInvoiceGrouping {
  BY_PROJECT_RESPONSIBLE = 'by_client_responsible',
  BY_CLIENT_RESPONSIBLE = 'by_project_responsible',
}

export enum ClientInvoiceLanguage {
  SV = 'sv',
  EN = 'en',
}

export interface IClientCategory {
  uuid: string;
  key: string;
  name: string;
  type: ClientType;
}

export interface IClientContact {
  uuid: string;
  name: string;
  short_name: null | string;
  contact_uuid: string;
  portal_status: PortalUserStatusType;
  is_deleted: boolean;
}

export interface IClientFullResource {
  uuid: string;
  name: string;
  org_number: string;
  city: string;
  main_phone: string;
  company_category: IClientCategory;
  invoice_address: {
    first_address: string;
    second_address: string;
    zip: string;
    city: string;
    country: string;
  };
  invoice_email: string;
  reg_address: {
    first_address: string;
    second_address: string;
    zip: string;
    city: string;
    country: string;
  };
  type: ClientType;
  vat: string;
  responsible_user: IUserPreviewResource;
  use_we_connect: boolean;
  invoice_default_contact: null | IClientContact;
  invoice_grouping: ClientInvoiceGrouping;
  invoice_default_language: ClientInvoiceLanguage;
  invoice_default_info: null | string;
  invoice_distribution: null | InvoiceDistributionType;
  current_financial_year: {
    start_date: string;
    end_date: string;
  };
  other_information: null | string;
  settings: {
    large_company: boolean;
    has_formal_board_meetings: boolean;
    prepares_financial_budgets: boolean;
    registered_as_employer: boolean;
    registered_for_vat: boolean;
    vat_reporting_frequency: ClientProcessesReportingFrequency | null;
    vat_reporting_deadline: string | null;
    registered_for_f_tax: boolean;
    part_of_company_group: boolean;
    holding_company: boolean;
    consolidates_group_financial_statement: boolean;
    dormant_limited_operations: boolean;
    customer_status: null | string;
    insourced_services: boolean;
    first_year_audit: boolean;
    audited_by_another_firm_previous_year: boolean;
  };
  tags: number[];
  created_with_credit_safe: boolean;
}

export interface IClientTagResource {
  id: number;
  name: string;
}

export interface IClientListResource {
  uuid: string;
  name: string;
  org_number: string;
  city: string;
  industry: string;
  fiscal_year_end: string;
  tags: IClientTagResource[];
  serviceCompanies: IServiceCompanyListResource[];
  serviceCompanyContacts: IServiceCompanyContactPreviewResource[];
  activeServices: IServicePreviewResource[];
  activeProjects: IProjectPreviewResource[];
  projectResponsible: IUserPreviewResource[];
  office: IOfficeResource;
}

export type IClientPreviewResource = Pick<IClientFullResource, 'uuid' | 'org_number' | 'name' | 'city'>;

export interface IClientUpdateInformationRequestBody {
  other_information: null | string;
}

export interface IClientRequestBody {
  type: ClientType;
  name: string;
  org_number: string;
  vat: string;
  category_uuid: string;
  main_phone: string;
  first_address: string;
  second_address: string;
  zip: string;
  city: string;
  country: string;
  invoice_email: string;
  invoice_first_address: string;
  invoice_second_address: string;
  invoice_zip: string;
  invoice_city: string;
  invoice_country: string;
  responsible_user_uuid: string;
}

export interface IClientStoreRequestBody extends IClientRequestBody {
  start_financial_year_date: string;
  end_financial_year_date: string;
}

export interface IClientUpdateRequestBody extends IClientRequestBody {
  invoice_default_contact_uuid: string;
  invoice_grouping: null | ClientInvoiceGrouping;
  invoice_default_language: ClientInvoiceLanguage;
  invoice_default_info: string;
  use_we_connect: boolean;
  current_financial_year_start_date: string;
  current_financial_year_end_date: string;
  invoice_distribution: null | InvoiceDistributionType;
  tags: number[];
  settings: {
    large_company: boolean;
    has_formal_board_meetings: boolean;
    prepares_financial_budgets: boolean;
    registered_as_employer: boolean;
    registered_for_vat: boolean;
    vat_reporting_frequency: ClientProcessesReportingFrequency | null;
    vat_reporting_deadline: string | null;
    registered_for_f_tax: boolean;
    part_of_company_group: boolean;
    holding_company: boolean;
    consolidates_group_financial_statement: boolean;
    dormant_limited_operations: boolean;
    customer_status: null | string;
    insourced_services: boolean;
    first_year_audit: boolean;
    audited_by_another_firm_previous_year: boolean;
    use_we_connect: boolean;
  };
}

export interface IClientSearchResource {
  result: {
    name: string;
    personal_number?: string;
    orgnumber?: string;
  };
}

export interface IClientBasicInfo {
  uuid: string;
  org_number: string;
  basic_data: {
    DIRECTORS: {
      NAME: string;
      FUNCTION: string;
      SOCSECURITYNR: string;
      APPOINTMENTDATE: string;
    }[];
    PAYROLL_DATA: {
      FEE: string;
      PAYROLL_DATE: string;
      REGISTER_DATE: string;
    }[];
    INACTIVE_DIRECTORS?: {
      NAME: string;
      FUNCTION: string;
      EXITINGDATE: string;
      SOCSECURITYNR: string;
      APPOINTMENTDATE: string;
    }[];
    GETDATA_RESPONSE: {
      [key: string]: string;
    };
    MAILING_ADDRESSES: {
      MAILING_ZIP?: string;
      MAILING_TOWN?: string;
      MAILING_ADDRESS?: string;
    };
    VISITING_ADDRESSES: {
      VISITING_ZIP?: string;
      VISITING_TOWN?: string;
      VISITING_ADDRESS?: string;
    };
  };
}

export interface IClientCreditInfo {
  uuid: string;
  org_number: string;
  rating: null | string;
  rating_text: null | string;
  kf_debt: null | string;
  antal_anm: null | string;
  sum_anm: null | string;
  status: null | string;
  registration_date: null | string;
  industry: null | string;
  industry_text: null | string;
  activity_text: null | string;
  share_capital: null | string;
  f_tax: null | boolean;
  f_tax_start_date: null | string;
  vat: null | boolean;
  emp_tax: null | boolean;
  vat_start_date: null | string;
  immediate_group_mother_name: null | string;
  immediate_group_org_number: null | string;
  company_sign: null | string;
  group_mother_name: null | string;
  group_mother_org_number: null | string;
  group_mother_country_code: null | string;
}

// Client billing resources
export interface IClientBillingSum {
  date: string;
  sum: number;
}

export interface IClientBillingInvoiceInvoice {
  id: number;
  internal_status: InvoiceInternalStatusType;
  external_status: null | InvoiceExternalStatusType;
  price: number;
}

export interface IClientBillingInvoice {
  date: string;
  forecasted_billing_plans: IProjectBillingPlanResource[];
  invoices: IClientBillingInvoiceInvoice[];
  billing_plans: IProjectBillingPlanResource[];
  postpones: IInvoiceItemPostponeResource[];
  total_sum: number;
}

export interface IClientBillingProject {
  name: string;
  id: number;
  invoiced: IClientBillingSum[];
  postponed: IClientBillingSum[];
  written_down: IClientBillingSum[];
  original: IClientBillingSum[];
}

export interface IClientBillingProjectsTotal {
  invoiced: IClientBillingSum[];
  postponed: IClientBillingSum[];
  original: IClientBillingSum[];
  written_down: IClientBillingSum[];
}

export interface IClientBillingProjects {
  data: IClientBillingProject[];
  total: IClientBillingProjectsTotal;
}

export interface IClientBillingListResource {
  invoices: IClientBillingInvoice[];
  projects: IClientBillingProjects;
}

// Client planning resources
export interface IClientPlanningPeriodMonth {
  month: string;
  count_weeks: number;
}

export interface IClientPlanningPeriodWeek {
  week: number;
  year: number;
  week_with_year: number;
  count_months: number;
  is_time_period_locked: boolean;
}

export interface IClientPlanningPeriod {
  months: IClientPlanningPeriodMonth[];
  weeks: IClientPlanningPeriodWeek[];
}

export type IClientPlanningProjectEvent = Pick<
  IPlanningEventResource,
  'has_tracked_time' | 'id' | 'is_done' | 'is_invoiced' | 'week'
> & {
  name: string;
  scheduled_time: number;
  time_sheet_locked: boolean;
  total_tracked_time: number;
  user: IUserPreviewResource;
};

export interface IClientPlanningProjectTask {
  id: number;
  name: string;
  events: IClientPlanningProjectEvent[];
}

export interface IClientPlanningProject {
  id: number;
  name: string;
  tasks: IClientPlanningProjectTask[];
}

export interface IClientPlanningResource {
  periods: IClientPlanningPeriod;
  projects: IClientPlanningProject[];
}

export enum ClientProcessesFramework {
  K1 = 'k1',
  K2 = 'k2',
  K3_LESS = 'k3_less',
  K3_BIGGER = 'k3_bigger',
  IFRS = 'ifrs',
  ARKL = 'arkl',
  OTHER = 'other',
}

export enum ClientProcessesReportingFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum ClientStatuses {
  PROSPECT = 'prospect',
  BLACKLIST = 'blacklist',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IClientProcesses {
  general_process_information: {
    description: string | null;
  };
  general_it_information: {
    description: string | null;
  };
  it_access_and_permissions: {
    description: string | null;
  };
  accounting_and_general_ledger: {
    enabled: boolean;
    framework: ClientProcessesFramework | null;
    deadline: string | null;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  salary_fringe_benefits_and_tax: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  supplier_invoices: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  receipts_and_travel_expense_reports: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  customer_invoices_and_collection: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  project_reporting_and_work_in_progress: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  accruals_and_prepayments: {
    enabled: false;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  bank_and_payments: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  loans_and_other_credits: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  cash_register_and_daily_sales: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  inventory: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  real_estate: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  fixed_assets: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  activates_internally_developed_assets: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  securities_trading: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  suspense_account_and_missing_documents: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  annual_closing_and_financial_statement: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  income_tax_return: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  vat: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
    reporting_deadline: string | null;
    reporting_frequency: ClientProcessesReportingFrequency | null;
    periodic_summary: boolean;
    deadline: string | null;
  };
  management_reporting: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
  archiving_and_backup: {
    enabled: boolean;
    system: number[];
    we_do: boolean;
    description: string | null;
  };
}

export interface IClientTagResource {
  id: number;
  name: string;
}

export interface IClientTagRequest {
  name: string;
}

export interface IClientItSystemResource {
  id: number;
  name: string;
}

export interface IClientItSystemRequest {
  name: string;
}

export interface IClientCheckpointListResource {
  uuid: string;
  name: string;
  responsible: IUserPreviewResource;
  projects: IProjectCheckpointListResource[];
}

export interface ClientFilesSettings {
  token: string;
  api_base_url: string;
}
