<script setup lang="ts">
import useAuthStore from '@/store/AuthStore';
import { AppAvatar } from '@/components/index';

const { authenticatedUser } = useAuthStore();
</script>

<template>
  <div class="dropdown-user">
    <AppAvatar :name="authenticatedUser.name" />
    <div class="user-details">
      <div class="text-700" v-text="authenticatedUser.name" />
      <div class="text-6 text-neutral-500" v-text="authenticatedUser.email" />
    </div>
  </div>
</template>
