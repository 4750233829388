<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { useModal } from 'vue-final-modal';

import {
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CheckpointEventModal,
  FontIcon,
} from '@/components';

import api from '@/services/api';
import progress from '@/services/progress';
import { IProjectCheckpointEventResource, IProjectCheckpointResource } from '@/types/Project';
import { CheckpointEventStateType } from '@/types/Checkpoint';

type Props = {
  title: string;
  checkpoints: IProjectCheckpointResource[];
  clientUuid: string;
};

const { title, checkpoints } = defineProps<Props>();

const expandedCheckpoints = ref<number[]>([]);

const { t, d, locale } = useI18n({ useScope: 'global' });

type Group = {
  year_month: number;
  events: IProjectCheckpointEventResource[];
};

const eventsGroupedByPeriod = computed(() => {
  return checkpoints.reduce((groups, checkpoint) => {
    checkpoint.events.forEach((event) => {
      if (groups.some((group) => group.year_month === event.year_month)) {
        groups.find((group) => group.year_month === event.year_month)!.events.push(event);
      } else {
        groups.push({
          year_month: event.year_month,
          events: [event],
        });
      }
    });
    groups.sort((group1, group2) => group1.year_month - group2.year_month);
    return groups;
  }, [] as Group[]);
});

function isExpandedPeriod(id: number) {
  return expandedCheckpoints.value.includes(id);
}

function expandPeriod(toExpandId: number) {
  expandedCheckpoints.value = [...expandedCheckpoints.value, toExpandId];
}

function collapsePeriod(toCollapseId: number) {
  expandedCheckpoints.value = expandedCheckpoints.value.filter((id) => id !== toCollapseId);
}

function hasDoneEvents(events: IProjectCheckpointEventResource[]) {
  return events.some((event) => event.state === CheckpointEventStateType.DONE);
}

function getDoneEvents(events: IProjectCheckpointEventResource[]) {
  return {
    completed: events.filter((event) => event.state === CheckpointEventStateType.DONE).length,
    total: events.length,
  };
}

function onEdit(event: IProjectCheckpointEventResource) {
  const { open, close, destroy } = useModal({
    component: CheckpointEventModal,
    attrs: {
      event,
      onUpdated(updatedEvent: IProjectCheckpointEventResource) {
        event.state = updatedEvent.state;
        event.state_changed_at = updatedEvent.state_changed_at;
        event.comment = updatedEvent.comment;
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onStateChange(event: IProjectCheckpointEventResource, e: Event) {
  const input = e.target as HTMLInputElement;
  const state = input.checked ? CheckpointEventStateType.DONE : CheckpointEventStateType.NOT_DONE;
  try {
    progress.start();
    input.disabled = true;
    const response = await api.clients.projects.checkpoints.events.update(event.id, { state });
    event.state = response.data.state;
    event.state_changed_at = response.data.state_changed_at;
  } catch (error) {
    console.error(error);
    input.checked = !input.checked;
  } finally {
    input.disabled = false;
    progress.done();
  }
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="mb-0">{{ title }}</h2>
      <RouterLink :to="{ name: 'client.checkpoints', params: { uuid: clientUuid } }" custom v-slot="{ navigate, href }">
        <a @click="navigate" :href="href" target="_blank">
          <AppButton light size="small">
            <FontIcon name="flag" />
            {{ t('project.view.open_checkpoints_overview') }}
          </AppButton>
        </a>
      </RouterLink>
    </div>
    <AppTable bordered>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap>{{ t('checkpoint.attributes.period') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('checkpoint.attributes.status') }}</AppTableTh>
          <AppTableTh class="text-right" nowrap>{{ t('common.actions') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody
        :class="{ 'is-expanded': isExpandedPeriod(group.year_month) }"
        v-for="group in eventsGroupedByPeriod"
        :key="group.year_month"
      >
        <AppTableTr>
          <AppTableTd class="font-bold">
            {{ DateTime.fromFormat(group.year_month.toString(), 'yyyyMM').setLocale(locale).toFormat('yyyy-MMM') }}
          </AppTableTd>
          <AppTableTd>
            {{
              t(
                `checkpoint.events_status.${hasDoneEvents(group.events) ? 'done' : 'not_done'}`,
                getDoneEvents(group.events),
              )
            }}
          </AppTableTd>
          <AppTableTd class="text-right" nowrap>
            <AppButton
              v-if="isExpandedPeriod(group.year_month)"
              @click.prevent="collapsePeriod(group.year_month)"
              light
              circle
              size="small"
            >
              <FontIcon name="chevron-up" />
            </AppButton>
            <AppButton v-else @click.prevent="expandPeriod(group.year_month)" light circle size="small">
              <FontIcon name="chevron-down" />
            </AppButton>
          </AppTableTd>
        </AppTableTr>
        <template v-if="isExpandedPeriod(group.year_month)">
          <AppTableTr
            @click.prevent="onEdit(event)"
            class="hoverable pointer"
            v-for="event in group.events"
            :key="event.id"
          >
            <AppTableTd>{{ event.name }}</AppTableTd>
            <AppTableTd>
              <div class="d-flex align-items-center">
                <div class="form-group mb-0" @click.stop>
                  <input
                    :id="`event-state-${event.id}`"
                    type="checkbox"
                    class="form-switch"
                    :checked="event.state === CheckpointEventStateType.DONE"
                    @change="onStateChange(event, $event)"
                  />
                  <label
                    :for="`event-state-${event.id}`"
                    class="form-label"
                    v-tooltip.bottom="`${t('common.updated')}: ${d(event.state_changed_at)}`"
                    v-text="'&nbsp;'"
                  />
                </div>
                <FontIcon
                  v-if="event.comment"
                  v-tooltip.bottom="event.comment"
                  class="text-3 text-neutral-500"
                  name="info-circle"
                />
              </div>
            </AppTableTd>
            <AppTableTd class="text-right" nowrap />
          </AppTableTr>
        </template>
      </AppTableBody>
    </AppTable>
  </div>
</template>

<style scoped lang="scss">
.is-expanded {
  border: 2px solid var(--color-neutral-400-hex);
}
</style>
