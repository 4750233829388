import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Services from '@/views/Services/Services.vue';
import Service from '@/views/Services/Service.vue';
import FixedPriceSettings from '@/views/Services/FixedPriceSettings.vue';
import usePermissions from '@/composables/usePermissions';

const prefix = 'services';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Services,
  },
  {
    path: `/${prefix}/create`,
    name: `${prefix}.create`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Service,
  },
  {
    path: `/${prefix}/edit/:uuid`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Service,
    props: true,
  },
  {
    path: `/${prefix}/fixed-price-settings`,
    name: `${prefix}.fixed-price-settings.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: FixedPriceSettings,
    beforeEnter: (to, from, next) => {
      const { can } = usePermissions();
      if (can('services.fixed-price-settings.manage')) {
        return next();
      }
      return next({ name: 'dashboard' });
    },
  },
];

export default routes;
