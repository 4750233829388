export default {
  modal: {
    modes: {
      title: 'Create activity/task',
      project_task: 'Client task',
      absence: 'Absence',
      activity: 'Internal activity',
    },
    project_task: {
      create_title: 'Create new client task',
      edit_title: 'Edit client task: {name}',
    },
    activity: {
      create_title: 'Create new internal activity',
      edit_title: 'Edit internal activity: {name}',
    },
    split: 'Split tasks',
    task_block_title: 'Task',
    additional_info_title: 'Additional info',
    internal_block_title: 'Internal activity',
    price_block_title: 'Price',
    project_base_fee: 'Project base fee',
    project_fee: 'Project fee',
    time_planned_block_title: 'Time planned',
    time_entries_block_title: 'Time entries',
    time_over_budget_block_title: 'Summary - distribution of time exceeding estimated',
    attributes: {
      activity: 'Activity',
      activity_type: 'Type',
      activity_group: 'Group',
      client: 'Client',
      project: 'Project',
      task: 'Task',
      frequency: 'Frequency',
      task_notes: 'Task notes',
      sub_label: 'Sublabel',
      activity_notes: 'Activity notes',
      service_price: 'Service price',
      charge_separately: 'Charge separately',
      price_type: 'Price type',
      employee: 'Employee',
      week: 'Week',
      start_week: 'Start week',
      end_week: 'End week',
      estimated_time: 'Estimated time',
      total_time: 'Total time',
      done: 'Done',
      amount: 'Amount, SEK',
      discount: 'Discount, %',
      date: 'Date',
      time: 'Time',
      comment: 'Comment',
      actions: 'Actions',
      billable: 'Billable',
      not_billable: 'Not billable',
      note: 'Note for invoicing',
      change_future_responsible: 'Change future responsible',
      change_future_scheduled_time: 'Change future scheduled time?',
      change_future_to_actual_time: 'Change future to actual time?',
      change_future_to_actual_time_description: 'Planned time is too short for the task.',
      deadline: 'Deadline',
      visit_is_booked: 'Visit is booked',
      add_mileage_allowance: 'Add mileage allowance',
      vehicle: 'Vehicle',
      mileage: 'Mileage (km)',
      reimbursement: 'Reimbursement',
    },
    split_events: {
      title: 'Split information',
      original_scheduled_time: 'Originally scheduled time',
      week: 'Week',
      time: 'Tracked time',
      employee: 'Of who',
    },
  },
  confirm: {
    cancel: {
      title: 'Cancel changes',
      text: 'Are you sure you want to cancel changes? All unsaved information will be lost.',
    },
  },
};
