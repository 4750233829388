import { readonly } from 'vue';
import { useModal } from 'vue-final-modal';
import { CloseProjectModal, ConfirmModal, CreateFinalInvoiceModal } from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import api from '@/services/api';
import { useI18n } from 'vue-i18n';
import { CloseProjectForm, CloseProjectType, IProjectResource } from '@/types/Project';
import toast from '@/services/toast';
import { useRouter } from 'vue-router';
import { IServicePreviewResource } from '@/types/Service';

export default function useProject() {
  const { t } = useI18n({ useScope: 'global' });
  const router = useRouter();

  const projectSteps = readonly({
    service: { order: 0, visible: true, icon: 'settings' },
    price: { order: 1, visible: true, icon: 'receipt' },
    team: { order: 2, visible: true, icon: 'users' },
    planning: { order: 3, visible: true, icon: 'calendar-week' },
    activate: { order: 4, visible: true, icon: 'table' },
    active: { order: 5, visible: false, icon: 'rocket' },
  });

  function calculateAverageCost(externalTotalPrice: number, totalMinutes: number): string | null {
    const totalTimeInHours = totalMinutes > 0 ? totalMinutes / 60 : 0;
    if (totalTimeInHours === 0) {
      return Number(0).toString();
    }
    const cost = externalTotalPrice / totalTimeInHours;
    if (cost > 0) {
      return cost.toFixed();
    }
    return null;
  }

  type OnReactivateProjectCallbacks = {
    onDone?: (data: IProjectResource) => Promise<void> | void;
    onError?: (error: unknown) => void;
    onFinally?: () => void;
  };

  function openReactivateProjectModal(
    project: Pick<IProjectResource, 'id' | 'name'>,
    clientUuid: string,
    callbacks?: OnReactivateProjectCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('project.reactivate_modal.title', { name: project.name }),
        confirmColor: 'success',
        async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            const response = await api.projects.reactivate(clientUuid, project.id);
            if (callbacks?.onDone) {
              callbacks?.onDone(response.data);
            }
            await close();
          } catch (error) {
            console.error(error);
            if (callbacks?.onError) {
              callbacks?.onError(error);
            }
          } finally {
            setLoading(false);
            if (callbacks?.onFinally) {
              callbacks?.onFinally();
            }
          }
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  }

  type OnDeleteProjectCallbacks = {
    onDone?: () => Promise<void> | void;
    onError?: (error: unknown) => void;
    onFinally?: () => void;
  };

  function openDeleteProjectModal(
    project: Pick<IProjectResource, 'id' | 'name'>,
    clientUuid: string,
    callbacks?: OnDeleteProjectCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('project.confirm.destroy.title'),
        message: t('project.confirm.destroy.text', { name: project.name }),
        async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            await api.projects.destroy(clientUuid, project.id);
            if (callbacks?.onDone) {
              callbacks?.onDone();
            }
            await close();
          } catch (error) {
            console.error(error);
            if (callbacks?.onError) {
              callbacks?.onError(error);
            }
          } finally {
            setLoading(false);
            if (callbacks?.onFinally) {
              callbacks?.onFinally();
            }
          }
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  }

  type OnCreateFinalInvoiceCallbacks = {
    onDone?: () => Promise<void> | void;
  };

  function openCreateFinalInvoiceModal(
    project: Pick<IProjectResource, 'id'>,
    clientUuid: string,
    callbacks?: OnCreateFinalInvoiceCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: CreateFinalInvoiceModal,
      attrs: {
        projectId: project.id,
        clientUuid,
        async onCreated(id: null | number) {
          if (id) {
            toast.success(t('common.created'));
            const { href } = router.resolve({ name: 'invoices.edit', params: { id } });
            window.open(href, '_blank');
          } else {
            toast.error(t('invoice.final_invoice_has_been_created.text'));
          }
          if (callbacks?.onDone) {
            await callbacks.onDone();
          }
          await close();
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  }

  type OnCancelOrRolloverProjectCallbacks = {
    onDone?: (updatedProject: IProjectResource | null, closeProjectForm: CloseProjectForm) => Promise<void> | void;
    onError?: (error: unknown) => void;
    onFinally?: () => void;
  };

  function openCancelOrRolloverProjectModal(
    type: 'cancel' | 'rollover',
    project: Pick<IProjectResource, 'id' | 'name' | 'has_unfinished_tasks'> & {
      service: Pick<IServicePreviewResource, 'uuid'>;
    },
    clientUuid: string,
    callbacks?: OnCancelOrRolloverProjectCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: CloseProjectModal,
      attrs: {
        type,
        clientUuid,
        project: {
          id: project.id,
          name: project.name,
          has_unfinished_tasks: project.has_unfinished_tasks,
        },
        async onConfirm(
          updatedProject: IProjectResource | null,
          closeProjectForm: CloseProjectForm & { type: CloseProjectType },
        ) {
          if (callbacks?.onDone) {
            callbacks.onDone(updatedProject, closeProjectForm);
          }
          const newProjectRoute = {
            name: 'projects.create',
            params: { uuid: clientUuid },
            query: {
              type: closeProjectForm.type,
              rollover_project_id: project.id,
              rollover_service_uuid: project.service.uuid,
              rollover_project_name: project.name,
              auto_price_increase: Number(closeProjectForm.autoPriceIncrease),
              price_increase_percent: closeProjectForm.priceIncreasePercent,
            },
          };

          if (closeProjectForm.newProjectPlan) {
            if (closeProjectForm.finalInvoice) {
              openCreateFinalInvoiceModal(project, clientUuid, {
                async onDone() {
                  await Promise.all([router.push(newProjectRoute), close()]);
                },
              });
            } else {
              await Promise.all([router.push(newProjectRoute), close()]);
            }
          } else {
            if (closeProjectForm.finalInvoice) {
              openCreateFinalInvoiceModal(project, clientUuid, {
                async onDone() {
                  await Promise.all([close()]);
                },
              });
            } else {
              await Promise.all([close()]);
            }
          }
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  }

  return {
    projectSteps,
    openReactivateProjectModal,
    openDeleteProjectModal,
    openCancelOrRolloverProjectModal,
    openCreateFinalInvoiceModal,
    calculateAverageCost,
  };
}
