<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';

import api from '@/services/api';
import toast from '@/services/toast';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { AppButton, AppCloseButton, FormInput, FormLabel, FormNumber } from '@/components';
import { IFreeDayRequest, IFreeDayResource } from '@/types/FreeDay';

type Props = {
  disabledDates: Date[];
  freeDay: null | IFreeDayResource;
};

const { disabledDates, freeDay } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [freeDay: IFreeDayResource];
  updated: [freeDay: IFreeDayResource];
  closed: [];
}>();

const { t, locale } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const loading = ref(false);

const form = reactive<IFreeDayRequest>({
  name: null,
  date: null,
  workday_percent: 0,
});

const tracker = useTrackChanges(form);

const disabled = computed(() => !(form.name && form.date));

async function submit() {
  loading.value = true;
  try {
    if (freeDay) {
      const response = await api.freeDays.update(freeDay.id, form);
      emit('updated', response.data);
      toast.success(t('common.messages.has_been_updated', { name: response.data.name }));
    } else {
      const response = await api.freeDays.store(form);
      emit('created', response.data);
      toast.success(t('common.messages.has_been_created', { name: response.data.name }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  form.name = freeDay?.name ?? null;
  form.date = freeDay?.date ?? null;
  form.workday_percent = freeDay?.workday_percent ?? 0;
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="freeDay ? t('free-day.edit.title') : t('free-day.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="name" required>{{ t('free-day.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="name" required />
            </div>
          </div>
        </div>

        <!-- Date -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="dp-input-date" required>
                {{ t('free-day.attributes.date') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="form.date"
                  model-type="format"
                  format="yyyy-MM-dd"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  auto-apply
                  text-input
                  :locale="locale"
                  uid="date"
                  required
                  :disabled-dates="disabledDates"
                  teleport
                  six-weeks="center"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>

        <!-- Workday percent -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="workday_percent" required>{{ t('free-day.attributes.workday') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormNumber id="workday_percent" v-model="form.workday_percent" :min="0" :max="100" :step="10" />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            v-if="freeDay"
            class="mt-2 mt-sm-0"
            color="success"
            :loading="loading"
            :disabled="disabled || !tracker.isModified.value"
          >
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
