<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import { useForm } from 'vee-validate';
import api from '@/services/api';
import toast from '@/services/toast';
import { AppAlert, AppButton, AppCloseButton, FormInput, FormLabel, FormSwitch } from '@/components';
import { CloseProjectForm, CloseProjectType, ICancelOrRolloverProjectOptions, IProjectResource } from '@/types/Project';
import useModalFeatures from '@/composables/useModalFeatures';

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const { type, clientUuid, project } = defineProps<{
  type: CloseProjectType;
  clientUuid: string;
  project: {
    id: number;
    name: string;
    has_unfinished_tasks: boolean;
  };
}>();

const emit = defineEmits<{
  confirm: [updatedProject: IProjectResource | null, closeProjectForm: CloseProjectForm & { type: CloseProjectType }];
  closed: [];
}>();

const { resetForm, defineField, handleSubmit, isSubmitting, setFieldValue } = useForm<CloseProjectForm>({
  initialValues: {
    finalInvoice: false,
    newProjectPlan: type === 'rollover',
    autoPriceIncrease: false,
    priceIncreasePercent: 0,
  },
});

const [finalInvoice] = defineField('finalInvoice');
const [newProjectPlan] = defineField('newProjectPlan');
const [autoPriceIncrease] = defineField('autoPriceIncrease');
const [priceIncreasePercent] = defineField('priceIncreasePercent');

const submit = handleSubmit(async (values) => {
  try {
    if (values.newProjectPlan) {
      emit('confirm', null, { ...values, type });
    } else {
      const response = await api.projects[type](clientUuid, project.id, {
        start_new_project: false,
      } as ICancelOrRolloverProjectOptions);
      emit('confirm', response.data, { ...values, type });
    }
    toast.success(t('common.updated'));
    resetForm();
  } catch (error) {
    console.error(error);
  }
});

onCtrlEnter(() => {
  submit();
});

async function beforeOpen() {
  try {
    const { data } = await api.services.fixedPriceSettings.current();
    if (data) {
      setFieldValue('priceIncreasePercent', data.annual_increase);
    }
  } catch (error: unknown) {
    console.error(error);
  }
}
</script>
<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
    @before-open="beforeOpen"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-container">
        <div class="modal-header">
          <h2 v-text="t(`project.${type}_modal.title`, { name: project.name })" />
          <AppCloseButton @close="close" />
        </div>
        <div class="modal-content">
          <div class="d-flex flex-column gap-3">
            <!-- Final invoice -->
            <div class="d-flex align-items-center">
              <FormLabel html-for="final_invoice">
                {{ t('project.cancel_modal.final_invoice') }}
              </FormLabel>
              <FormSwitch group-class="mb-0" id="final_invoice" v-model="finalInvoice" />
            </div>

            <!-- New project plan -->
            <div class="d-flex align-items-center gap-2">
              <FormLabel html-for="new_project_plan">
                {{ t('project.cancel_modal.new_project_plan') }}
              </FormLabel>
              <FormSwitch group-class="mb-0" id="new_project_plan" v-model="newProjectPlan" />
            </div>

            <template v-if="newProjectPlan">
              <!-- Auto price increase -->
              <div class="d-flex align-items-center gap-2">
                <FormLabel html-for="auto_price_increase">
                  {{ t('project.cancel_modal.auto_price_increase') }}
                </FormLabel>
                <FormSwitch group-class="mb-0" id="auto_price_increase" v-model="autoPriceIncrease" />
              </div>
              <!-- Price increase percent -->
              <div v-if="autoPriceIncrease" class="d-flex align-items-center">
                <FormLabel html-for="price_increase_percent">
                  {{ t('project.cancel_modal.price_increase_percent') }}
                </FormLabel>
                <FormInput
                  group-styles="width: 100px;"
                  type="number"
                  v-model="priceIncreasePercent"
                  id="price_increase_percent"
                  icon="percentage"
                  required
                  :invalid="!(typeof priceIncreasePercent === 'number')"
                  step="0.01"
                />
              </div>
            </template>
          </div>
          <AppAlert class="mt-4 p-3 text-5" v-if="project.has_unfinished_tasks" type="danger">
            {{ t('project.cancel_modal.warning') }}
          </AppAlert>
        </div>
        <div class="modal-footer d-flex">
          <AppButton type="button" light @click.prevent="close" :disabled="isSubmitting">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="ml-auto" color="secondary" :loading="isSubmitting">
            {{ t('common.confirm') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
