<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import { useForm } from 'vee-validate';
import { AppButton, FormLabel, FormInput, FormDatePicker } from '@/components';
import { IFixedPriceSettingsRequest, IFixedPriceSettingsResource } from '@/types/Service';
import useModalFeatures from '@/composables/useModalFeatures';
import api from '@/services/api';
import toast from '@/services/toast';

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

type Props = {
  fixedPriceSetting?: IFixedPriceSettingsResource;
};

const { fixedPriceSetting } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [fixedPriceSetting: IFixedPriceSettingsResource];
  updated: [fixedPriceSetting: IFixedPriceSettingsResource];
  closed: [];
}>();

const { resetForm, defineField, handleSubmit, meta, setFieldValue, isSubmitting } = useForm<IFixedPriceSettingsRequest>(
  {
    initialValues: {
      date: fixedPriceSetting?.date ?? null,
      annual_increase: fixedPriceSetting?.annual_increase ?? null,
    },
  },
);

const [date] = defineField('date');
const [annualIncrease] = defineField('annual_increase');

const submit = handleSubmit(async (values) => {
  try {
    if (fixedPriceSetting) {
      const response = await api.services.fixedPriceSettings.update(fixedPriceSetting.id, values);
      toast.success(t('common.updated'));
      emit('updated', response.data);
    } else {
      const response = await api.services.fixedPriceSettings.create(values);
      toast.success(t('common.created'));
      emit('created', response.data);
    }
    resetForm();
  } catch (error) {
    console.error(error);
  }
});

onMounted(() => {
  if (fixedPriceSetting) {
    setFieldValue('date', fixedPriceSetting.date);
    setFieldValue('annual_increase', fixedPriceSetting.annual_increase);
  }
});

onCtrlEnter(() => {
  if (meta.value.valid) {
    submit();
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @keydown.enter.prevent @submit.prevent="submit">
      <div class="modal-header">
        <h2
          v-text="
            fixedPriceSetting
              ? t('service.fixed_price_settings.edit.title')
              : t('service.fixed_price_settings.create.title')
          "
        />
      </div>
      <div class="modal-content">
        <!-- Date -->
        <div class="row form-group align-items-center">
          <div class="col-3">
            <FormLabel html-for="dp-input-fixed_price_date" required>
              {{ t('service.fixed_price_settings.attributes.date') }}
            </FormLabel>
          </div>
          <div class="col">
            <FormDatePicker id="fixed_price_date" v-model="date" required :min-date="new Date()" />
          </div>
        </div>
        <!-- Annual increase -->
        <div class="row form-group align-items-center">
          <div class="col-3">
            <FormLabel html-for="fixed_price_annual_increase" required>
              {{ t('service.fixed_price_settings.attributes.annual_increase') }}
            </FormLabel>
          </div>
          <div class="col">
            <FormInput
              type="number"
              v-model="annualIncrease"
              id="fixed_price_annual_increase"
              icon="percentage"
              required
              step="0.01"
              min="0.01"
            />
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer d-flex">
        <AppButton type="button" light @click.prevent="close">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton v-if="fixedPriceSetting" class="ml-auto" color="success" :loading="isSubmitting">
          {{ t('common.update') }}
        </AppButton>
        <AppButton v-else class="ml-auto" color="secondary" :loading="isSubmitting">
          {{ t('common.create') }}
        </AppButton>
      </div>
    </form>
  </VueFinalModal>
</template>
