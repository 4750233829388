<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { set } from 'lodash';
import api from '@/services/api';
import {
  AppAlert,
  AppButton,
  AppCollapse,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ColumnSettings,
  EmptyValue,
  FontIcon,
  FormInput,
  FormLabel,
  HelpInformation,
  TodoCommentsModal,
  TodoStatusBadge,
  TodoTypeBadge,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import VueSelect from 'vue-select';
import { useTitle } from '@vueuse/core';
import { IGetAllTodosRequest, ITodoResource, TodoType } from '@/types/Todo';
import { SelectOption, SortOrder } from '@/types/Common';
import useLoadingButton from '@/composables/useLoadingButton';
import { DateTime } from 'luxon';
import { IUserListResource, UserStatusType } from '@/types/User';
import useUsers from '@/composables/useUsers';
import useClients from '@/composables/useClients';
import { IClientPreviewResource } from '@/types/Client';
import useTodos from '@/composables/useTodos';
import { useRoute } from 'vue-router';
import useAuthStore from '@/store/AuthStore';
import { useModal } from 'vue-final-modal';

const { authenticatedUser } = useAuthStore();
const { t, d, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const todos = ref<ITodoResource[]>([]);
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(25);
const total = ref(0);
const { clients, onSearchClients } = useClients();
const { query } = useRoute();
const { users, usersLoading, getUsers } = useUsers();
const todosAbortController = ref<null | AbortController>(null);

const { columns, isColumnEnable, enabledColumns } = useColumnSettings('todos_columns_v2', [
  'type',
  'recipient',
  'deadline',
  'status',
  'created_by',
  'updated_at',
]);

const filterLoader = useLoader();
const sortLoader = useLoader();

const defaultFilters: IGetAllTodosRequest = {
  fromDate: DateTime.now().minus({ months: 3 }).toFormat('yyyy-MM-dd'),
  toDate: DateTime.now().toFormat('yyyy-MM-dd'),
  sortOrder: SortOrder.Desc,
  sortBy: 'updated_at',
  client: null,
  creator: null,
  recipient: null,
  type: null,
  status: 'open',
  search: '',
};

const filters = reactive<IGetAllTodosRequest>({
  ...defaultFilters,
});

async function resetFilters() {
  filters.fromDate = defaultFilters.fromDate;
  filters.toDate = defaultFilters.toDate;
  filters.sortBy = defaultFilters.sortBy;
  filters.sortOrder = defaultFilters.sortOrder;
  filters.client = defaultFilters.client;
  filters.creator = defaultFilters.creator;
  filters.recipient = defaultFilters.recipient;
  filters.type = defaultFilters.type;
  filters.status = defaultFilters.status;
  filters.search = defaultFilters.search;
  await onFilter();
}

async function getTodos() {
  if (todosAbortController.value instanceof AbortController) {
    todosAbortController.value.abort('Abort request: getTodos');
  }
  try {
    todosAbortController.value = new AbortController();
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('pageSize', perPage.value.toString());
    if (filters.sortBy) searchParams.append('sortBy', filters.sortBy);
    if (filters.sortOrder) searchParams.append('sortOrder', filters.sortOrder);
    if (filters.search) searchParams.append('search', filters.search);
    if (filters.fromDate) searchParams.append('fromDate', filters.fromDate);
    if (filters.toDate) searchParams.append('toDate', filters.toDate);
    if (filters.client) searchParams.append('filterClientId', filters.client);
    if (filters.creator) searchParams.append('filterUserId', filters.creator);
    if (filters.recipient) searchParams.append('filterReceiverId', filters.recipient);
    if (filters.type) searchParams.append('type', filters.type);
    if (filters.status) searchParams.append('status', filters.status);
    const response = await api.todos.all({ searchParams, signal: todosAbortController.value.signal });
    todos.value = response.list;
    total.value = response.total;
  } catch (error) {
    console.error(error);
  } finally {
    todosAbortController.value = null;
  }
}

async function onFilter() {
  filterLoader.start();
  page.value = 1;
  await getTodos();
  filterLoader.finish();
}

const { onCreate, onEdit, onDelete } = useTodos({
  onCreated() {
    getTodos();
  },
  onUpdated() {
    getTodos();
  },
  async onDeleted() {
    await getTodos();
    if (todos.value.length === 0 && page.value > 1) {
      page.value -= 1;
    }
  },
});

async function onClose(event: PointerEvent, todo: ITodoResource) {
  const { setLoading } = useLoadingButton(event);
  try {
    setLoading(true);
    await api.todos.close(todo.id);
    await getTodos();
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}

async function onReopen(event: PointerEvent, todo: ITodoResource) {
  const { setLoading } = useLoadingButton(event);
  try {
    setLoading(true);
    await api.todos.reopen(todo.id);
    await getTodos();
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}

function onShowComments(todo: ITodoResource) {
  const { open, close, destroy, patchOptions } = useModal({
    component: TodoCommentsModal,
    attrs: {
      todoId: todo.id,
      comments: todo.comments,
      onAddComment(text: string) {
        const comment = {
          id: DateTime.now().toUnixInteger(),
          text,
          createdAt: DateTime.now().toString(),
          createdByUserUuid: authenticatedUser.uuid,
          authorUser: {
            uuid: authenticatedUser.uuid,
            name: authenticatedUser.name,
          },
        };
        todo.comments.push(comment);
        patchOptions({ attrs: { todoId: todo.id, comments: todo.comments } });
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

watch(page, async () => {
  loader.start();
  await getTodos();
  loader.finish();
});

watch([() => filters.sortBy, () => filters.sortOrder], async () => {
  sortLoader.start();
  await getTodos();
  sortLoader.finish();
});

onMounted(async () => {
  if (Object.keys(query).length) {
    loader.start();
  }

  const searchParams = new URLSearchParams();
  searchParams.append('without_pagination', '1');
  searchParams.append('statuses[]', UserStatusType.Active);
  searchParams.append('statuses[]', UserStatusType.Invited);
  await getUsers({ searchParams });

  Object.keys(query).forEach((key) => {
    if (key in filters) {
      set(filters, key, query[key]);
    }
  });
  if (Object.keys(query).length) {
    await getTodos();
    loader.finish();
  }
});

const title = useTitle(computed(() => t('todo.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="todo.index.help" />
      </div>
      <AppButton @click.prevent="onCreate({})" class="ml-auto" color="secondary">
        {{ t('todo.index.create') }}
      </AppButton>
      <Dropdown placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`todo.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse opened class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 align-items-end">
          <!-- Filter search -->
          <div class="form-group col">
            <FormLabel html-for="filter_search">
              {{ t('todo.filters.search') }}
            </FormLabel>
            <FormInput type="search" v-model="filters.search" id="filter_search" icon="search" />
          </div>

          <!-- From date -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter_from_date">
              {{ t('todo.filters.from_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_from_date"
                :ui="{ input: 'form-control' }"
                v-model="filters.fromDate"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :clearable="false"
                :max-date="filters.toDate"
                six-weeks="center"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>

          <!-- To date -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter_to_date">
              {{ t('todo.filters.to_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_to_date"
                :ui="{ input: 'form-control' }"
                v-model="filters.toDate"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :clearable="false"
                :min-date="filters.fromDate"
                six-weeks="center"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>

          <!-- Created by -->
          <div class="form-group col">
            <FormLabel html-for="filter_created_by">
              {{ t('todo.filters.created_by') }}
            </FormLabel>
            <VueSelect
              v-model="filters.creator"
              :options="users"
              label="name"
              input-id="filter_created_by"
              :loading="usersLoading"
              :disabled="usersLoading"
              :reduce="(option: IUserListResource) => option.uuid"
              :placeholder="usersLoading ? t('common.loading') : t('common.all')"
            />
          </div>

          <!-- Receiver -->
          <div class="form-group col">
            <FormLabel html-for="filter_recipient">
              {{ t('todo.filters.recipient') }}
            </FormLabel>
            <VueSelect
              v-model="filters.recipient"
              :options="users"
              label="name"
              input-id="filter_recipient"
              :loading="usersLoading"
              :disabled="usersLoading"
              :reduce="(option: IUserListResource) => option.uuid"
              :placeholder="usersLoading ? t('common.loading') : t('common.all')"
            />
          </div>

          <!-- Client -->
          <div class="form-group col">
            <FormLabel html-for="filter_client">
              {{ t('todo.filters.client') }}
            </FormLabel>
            <VueSelect
              :filterable="false"
              @search="onSearchClients"
              v-model="filters.client"
              :reduce="(option: IClientPreviewResource) => option.uuid"
              :options="clients"
              label="name"
              input-id="filter_client"
              :placeholder="t('common.search')"
              :clear-search-on-blur="() => true"
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>

          <!-- Type -->
          <div class="form-group col">
            <FormLabel html-for="filter_type">
              {{ t('todo.filters.type') }}
            </FormLabel>
            <VueSelect
              style="--vs-dropdown-max-height: 180px"
              v-model="filters.type"
              :options="[
                { label: t('todo.type.Info'), value: 'info' },
                { label: t('todo.type.ToDo'), value: 'todo' },
                { label: t('todo.type.Message'), value: 'message' },
                { label: t('todo.message_or_todo'), value: 'messageortodo' },
              ]"
              :reduce="(option: SelectOption) => option.value"
              label="label"
              input-id="filter_type"
              :placeholder="t('common.all')"
            />
          </div>

          <!-- Status -->
          <div class="form-group col">
            <FormLabel html-for="filter_status">
              {{ t('todo.filters.status') }}
            </FormLabel>
            <VueSelect
              v-model="filters.status"
              :options="[
                { label: t('todo.status.opened'), value: 'open' },
                { label: t('todo.status.closed'), value: 'closed' },
              ]"
              :reduce="(option: SelectOption) => option.value"
              label="label"
              input-id="filter_status"
              :placeholder="t('common.all')"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="filterLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="todos.length === 0">
        {{ t('common.apply_filters_to_show_data') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead v-model:sort-by="filters.sortBy" v-model:sort-order="filters.sortOrder">
            <AppTableTr>
              <AppTableTh sortable="type" nowrap v-if="isColumnEnable('type')">
                {{ t('todo.attributes.type') }}
              </AppTableTh>
              <AppTableTh sortable="updated_at" nowrap v-if="isColumnEnable('updated_at')">
                {{ t('todo.attributes.updated_at') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('created_by')">
                {{ t('todo.attributes.created_by') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('recipient')">
                {{ t('todo.attributes.recipient') }}
              </AppTableTh>
              <AppTableTh sortable="text" nowrap>
                {{ t('todo.attributes.text') }}
              </AppTableTh>
              <AppTableTh nowrap sortable="deadline" v-if="isColumnEnable('deadline')">
                {{ t('todo.attributes.deadline') }}
              </AppTableTh>
              <AppTableTh nowrap sortable="status" v-if="isColumnEnable('status')">
                {{ t('todo.attributes.status') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="todo in todos" :key="todo.id">
              <AppTableTd nowrap v-if="isColumnEnable('type')">
                <TodoTypeBadge :type="todo.type" />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('updated_at')">
                {{ d(todo.updatedAt) }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('created_by')">
                {{ todo.authorUser.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('recipient')">
                <RouterLink
                  v-if="todo.client"
                  target="_blank"
                  :to="{ name: 'client.overview', params: { uuid: todo.client.uuid } }"
                >
                  <span v-if="todo.recepientUser" v-text="todo.receiptantName" />
                  <span v-else v-text="todo.client.name" />
                </RouterLink>
                <template v-else>
                  <span v-if="todo.recepientUser" v-text="todo.recepientUser.name" />
                  <span v-else v-text="t('common.all')" />
                </template>
              </AppTableTd>
              <AppTableTd>
                <span v-if="todo.text" v-text="todo.text" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('deadline')">
                <span v-if="todo.deadline" v-text="d(todo.deadline)" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('status')">
                <TodoStatusBadge :closed="todo.isClosed" :closed-at="todo.closedAt" />
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <template
                  v-if="
                    todo.recepientUserUuid === authenticatedUser.uuid ||
                    (todo.recepientUserUuid === null && todo.createdByUserUuid === authenticatedUser.uuid)
                  "
                >
                  <AppButton
                    v-if="!todo.isClosed"
                    v-tooltip.left="t('todo.tooltip.complete')"
                    @click.stop="onClose($event, todo)"
                    class="ml-2"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="check" />
                  </AppButton>
                  <AppButton
                    v-if="todo.isClosed"
                    v-tooltip.left="t('todo.tooltip.reopen')"
                    @click.stop="onReopen($event, todo)"
                    class="ml-2"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="rotate" />
                  </AppButton>
                </template>
                <AppButton
                  v-if="
                    todo.type === TodoType.Message &&
                    (todo.recepientUserUuid === null || todo.recepientUserUuid === authenticatedUser.uuid)
                  "
                  v-tooltip.left="t('user.todos.tooltip.answer')"
                  @click.stop="onShowComments(todo)"
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="arrow-back-up" />
                </AppButton>
                <AppButton
                  v-if="todo.type === TodoType.Info || todo.type === TodoType.Todo"
                  v-tooltip.left="t('user.todos.tooltip.comment')"
                  @click.stop="onShowComments(todo)"
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="messages" />
                </AppButton>
                <template v-if="todo.createdByUserUuid === authenticatedUser.uuid && !todo.isClosed">
                  <AppButton
                    v-tooltip.left="t('todo.tooltip.edit')"
                    @click.stop="onEdit(todo)"
                    class="ml-2"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                  <AppButton
                    v-tooltip.left="t('todo.tooltip.destroy')"
                    @click.stop="onDelete(todo)"
                    class="ml-2"
                    color="danger"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="trash" />
                  </AppButton>
                </template>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
