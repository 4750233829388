import { DateTime } from 'luxon';

export default function useDate() {
  function convertWeekNumberToDates(weekNumber: number): [Date, Date] {
    const year = weekNumber.toString().substring(0, 4);
    const week = weekNumber.toString().substring(4);
    return [
      DateTime.fromISO(`${year}-W${week}-1`).startOf('week').toJSDate(),
      DateTime.fromISO(`${year}-W${week}-1`).endOf('week').toJSDate(),
    ];
  }

  function convertWeekNumberToDateRange(week: number): [Date, Date] {
    const y = week.toString().substring(0, 4);
    const w = week.toString().substring(4);
    const startDate = DateTime.fromISO(`${y}-W${w}-1`).startOf('week').toJSDate();
    const endDate = DateTime.fromISO(`${y}-W${w}-1`).endOf('week').toJSDate();
    return [startDate, endDate];
  }

  function convertDateRangeToWeekNumber(dates: [Date, Date]): number {
    return parseInt(DateTime.fromJSDate(dates[0]).plus({ days: 1 }).toFormat('kkkkWW'));
  }

  function convertDatesToWeekNumber(dates?: [Date, Date] | null): string | null {
    if (Array.isArray(dates)) {
      return DateTime.fromJSDate(dates[0]).plus({ days: 1 }).toFormat('kkkkWW');
    }
    return null;
  }

  function getWeeksBetweenStartAndEnd(startDate: string, endDate: string): number[] {
    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);

    const weeks = [];

    for (let current = start; current <= end.endOf('week'); current = current.plus({ weeks: 1 })) {
      const year = current.toFormat('kkkk');
      const week = current.weekNumber.toString().padStart(2, '0');
      weeks.push(Number(`${year}${week}`));
    }

    return weeks;
  }

  function getFirstDayOfWeek(dateString: string) {
    const date = DateTime.fromISO(dateString);
    const dayOfWeek = date.weekday;
    const daysUntilStartOfWeek = dayOfWeek - 1;
    const firstDayOfWeek = date.minus({ days: daysUntilStartOfWeek });
    return firstDayOfWeek.toJSDate();
  }

  function getLastDayOfWeek(dateString: string) {
    const date = DateTime.fromISO(dateString);
    const dayOfWeek = date.weekday;
    const daysUntilEndOfWeek = 7 - dayOfWeek;
    const lastDayOfWeek = date.plus({ days: daysUntilEndOfWeek });
    return lastDayOfWeek.toJSDate();
  }

  function getCurrentWeek(): number {
    const now = DateTime.local();
    return now.weekNumber;
  }

  function isWeekCurrent(weekNumber: number): boolean {
    return getCurrentWeek() === weekNumber;
  }

  function getCurrentYear(): number {
    const now = DateTime.local();
    return now.year;
  }

  function getCurrentYearAndWeek(): number {
    const currentWeek = getCurrentWeek().toString().padStart(2, '0');

    return Number.parseInt(`${getCurrentYear()}${currentWeek}`);
  }

  function getCurrentDate() {
    return new Date();
  }

  function getFirstDateOfWeek(yearAndWeek: number | string) {
    const yearAndWeekNumber = typeof yearAndWeek === 'string' ? parseInt(yearAndWeek) : yearAndWeek;
    const year = Math.floor(yearAndWeekNumber / 100);
    const week = yearAndWeekNumber % 100;
    return DateTime.fromObject({ weekYear: year, weekNumber: week, weekday: 1 });
  }

  function getWeekDates(weekNumber: number): string[] {
    const year = Math.floor(weekNumber / 100);
    const week = weekNumber % 100;
    const firstDayOfWeek = DateTime.fromObject({ weekYear: year, weekNumber: week, weekday: 1 });
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = firstDayOfWeek.plus({ days: i });
      weekDates.push(date.toFormat('yyyy-MM-dd'));
    }
    return weekDates;
  }

  function isWeekGreaterThanCurrent(weekNumber: number, orEqual = false): boolean {
    if (orEqual) {
      return weekNumber >= getCurrentYearAndWeek();
    }
    return weekNumber > getCurrentYearAndWeek();
  }

  function isWeekLessThanCurrent(weekNumber: number, orEqual = false): boolean {
    if (orEqual) {
      return weekNumber <= getCurrentYearAndWeek();
    }
    return weekNumber < getCurrentYearAndWeek();
  }

  function mathWeeks(week: number, count: number, action: 'plus' | 'minus') {
    const dates = convertWeekNumberToDateRange(week);
    const start = DateTime.fromJSDate(dates[0])[action]({ week: count }).toJSDate();
    const end = DateTime.fromJSDate(dates[1])[action]({ week: count }).toJSDate();
    return convertDateRangeToWeekNumber([start, end]);
  }

  function findEarliestAndLatestDates(dates: Date[]) {
    const dateTimes = dates.map((date) => DateTime.fromJSDate(date));
    const earliestDate = dateTimes.reduce((earliest, current) => (current < earliest ? current : earliest));
    const latestDate = dateTimes.reduce((latest, current) => (current > latest ? current : latest));
    return {
      earliest: earliestDate.toISO() as string,
      latest: latestDate.toISO() as string,
    };
  }

  function getWeekNumber(rawDate: string | Date) {
    const date = typeof rawDate === 'string' ? DateTime.fromISO(rawDate) : DateTime.fromJSDate(rawDate);
    return Number.parseInt(date.plus({ days: 1 }).toFormat('kkkkWW'));
  }

  function formatYearWeek(yearWeek?: number | string | null) {
    if (!yearWeek) return '';
    const yearWeekStr = yearWeek.toString();
    const year = yearWeekStr.slice(0, 4);
    const week = yearWeekStr.slice(4, 6);
    return `${year}-${week}`;
  }

  return {
    convertWeekNumberToDates,
    convertDatesToWeekNumber,
    getWeeksBetweenStartAndEnd,
    getFirstDayOfWeek,
    getLastDayOfWeek,
    getCurrentWeek,
    getCurrentYear,
    getCurrentYearAndWeek,
    getFirstDateOfWeek,
    getWeekDates,
    getCurrentDate,
    isWeekGreaterThanCurrent,
    isWeekLessThanCurrent,
    convertWeekNumberToDateRange,
    convertDateRangeToWeekNumber,
    mathWeeks,
    findEarliestAndLatestDates,
    getWeekNumber,
    formatYearWeek,
    isWeekCurrent,
  };
}
