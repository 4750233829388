<script setup lang="ts">
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  FormLabel,
} from '@/components';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref, watch, reactive } from 'vue';
import useLoader from '@/composables/useLoader';
import api from '@/services/api';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { IProjectPreviewResource, IProjectTaskListResource, ProjectStatusType } from '@/types/Project';
import { useRouteQuery } from '@vueuse/router';
import { IEventReportedTimeFullResource, TaskStatusOptions } from '@/types/Event';
import useTime from '@/composables/useTime';
import { IUserListResource, UserStatusType } from '@/types/User';

const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { convertMinutesToTime } = useTime();

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const taskStatusOptions: TaskStatusOptions[] = [
  TaskStatusOptions.ALL,
  TaskStatusOptions.DONE,
  TaskStatusOptions.NOT_DONE,
];

const viewPerPageOptions = computed(() => ['100', 'All']);

const filterProject = useRouteQuery<string>('project', '');
const filterFrom = useRouteQuery<string>('from', '');
const filterTo = useRouteQuery<string>('to', '');

const filters = reactive<{
  tasks_id: number[];
  users_uuid: string[];
  without_pagination: string;
  status: string;
  dirty: boolean;
}>({
  tasks_id: [],
  users_uuid: [],
  without_pagination: '100',
  status: 'all',
  dirty: false,
});

const currentPage = useRouteQuery('page', '1', { transform: Number });

const filterDisabled = computed(() => loader.isLoading.value);

const perPage = ref(100);
const total = ref(0);

const events = ref<IEventReportedTimeFullResource[]>([]);
const users_events = ref<IEventReportedTimeFullResource[]>([]);

const projects = ref<IProjectPreviewResource[]>([]);
const projectLoading = ref(false);

const tasks = ref<IProjectTaskListResource[]>([]);
const taskLoading = ref(false);

const users = ref<IUserListResource[]>([]);
const userLoading = ref(false);

async function getEvents() {
  try {
    loader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());

    if (filterProject.value) searchParams.append('projects_id[]', filterProject.value);

    filters.tasks_id.forEach((id) => searchParams.append('tasks_id[]', id.toString()));
    filters.users_uuid.forEach((uuid) => searchParams.append('users_uuid[]', uuid));

    if (filterFrom.value) searchParams.append('created_from_date', filterFrom.value);
    if (filterTo.value) searchParams.append('created_to_date', filterTo.value);

    if (filters.status && filters.status !== 'all') searchParams.append('status', filters.status);
    if (filters.without_pagination && filters.without_pagination === 'All')
      searchParams.append('without_pagination', '1');

    const response = await api.clients.events.index(uuid, { searchParams });

    events.value = response.events.data;
    users_events.value = response.users_events;
    perPage.value = response.meta.per_page;
    total.value = response.meta.total;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

async function getProjects() {
  try {
    projectLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', ProjectStatusType.Active);
    searchParams.append('statuses[]', ProjectStatusType.Cancelled);
    searchParams.append('statuses[]', ProjectStatusType.Done);
    const response = await api.clients.projects.list(uuid, { searchParams });
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectLoading.value = false;
  }
}

async function getTasks() {
  try {
    taskLoading.value = true;
    const searchParams = new URLSearchParams();
    const response = await api.clients.projects.tasks.list(uuid, searchParams);
    tasks.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    taskLoading.value = false;
  }
}

async function getUsers() {
  try {
    userLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', UserStatusType.Active);
    searchParams.append('statuses[]', UserStatusType.Invited);
    searchParams.append('statuses[]', UserStatusType.Blocked);
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    userLoading.value = false;
  }
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getEvents();
  loader.finish();
  filters.dirty = true;
}

async function resetFilters() {
  filterProject.value = '';
  filterFrom.value = '';
  filterTo.value = '';

  filters.tasks_id = [];
  filters.users_uuid = [];
  filters.status = 'all';
  filters.without_pagination = '100';
  currentPage.value = 1;

  await onFilter();
  filters.dirty = false;
}

// get all clients times
function getTime(events: IEventReportedTimeFullResource[]) {
  let sum_estimated_time = 0;
  let sum_tracked_time = 0;
  let sum_billable_over_budget_time = 0;
  let sum_not_billable_over_budget_time = 0;

  events.map((event: IEventReportedTimeFullResource) => {
    sum_estimated_time += event.scheduled_time;
    sum_tracked_time += event.tracked_time;
    sum_billable_over_budget_time += event.billable_time;
    sum_not_billable_over_budget_time += event.not_billable_time;
  });

  return {
    sum_estimated_time,
    sum_tracked_time,
    sum_billable_over_budget_time,
    sum_not_billable_over_budget_time,
  };
}

watch(currentPage, getEvents);

onMounted(async () => {
  await Promise.all([getEvents(), getProjects(), getTasks(), getUsers()]);
});
</script>

<template>
  <AppBox shadow>
    <AppBoxBody>
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h2 v-text="t('client.reported_time.title')" />
      </div>
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-sm-2 row-cols-sm-3 row-cols-md-4">
          <div class="form-group col">
            <FormLabel html-for="filter_project">
              {{ t('client.reported_time.attributes.project') }}
            </FormLabel>
            <VueSelect
              :clearable="true"
              v-model="filterProject"
              :options="projects"
              :reduce="(option: IProjectPreviewResource) => option.id.toString()"
              label="name"
              input-id="filter_project"
              :placeholder="t('common.all')"
              :disabled="projectLoading || filterDisabled"
              :loading="projectLoading"
            />
          </div>
          <div class="form-group col">
            <FormLabel html-for="filter_tasks">
              {{ t('client.reported_time.attributes.task') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="filters.tasks_id"
              :options="tasks"
              :reduce="(option: IProjectTaskListResource) => option.id"
              label="name"
              input-id="filter_tasks"
              :placeholder="t('common.all')"
              :disabled="taskLoading || filterDisabled"
              :loading="taskLoading"
              ref="filterTaskDropdown"
              multiple="multiple"
            />
          </div>
          <div class="form-group col">
            <FormLabel html-for="responsible">
              {{ t('client.reported_time.attributes.employee') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="filters.users_uuid"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="responsible"
              :placeholder="t('common.all')"
              :disabled="userLoading || filterDisabled"
              :loading="userLoading"
              ref="filterUserDropdown"
              multiple="multiple"
            >
              <template #search="{ attributes, events: attributeEvents }">
                <input class="vs__search" v-bind="attributes as object" v-on="attributeEvents" />
              </template>
            </VueSelect>
          </div>
          <div class="form-group col">
            <FormLabel html-for="filter_project">
              {{ t('client.reported_time.attributes.from_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                :ui="{ input: 'form-control' }"
                :placeholder="t('common.not_selected')"
                v-model="filterFrom"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                week-numbers="iso"
                :week-num-name="t('common.week_short')"
                :max-date="filterTo ? new Date(filterTo) : ''"
                :disabled="filterDisabled"
                text-input
                :locale="locale"
                six-weeks="center"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <div class="form-group col">
            <FormLabel html-for="filter_project">
              {{ t('client.reported_time.attributes.to_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                :ui="{ input: 'form-control' }"
                :placeholder="t('common.not_selected')"
                v-model="filterTo"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                week-numbers="iso"
                :week-num-name="t('common.week_short')"
                :min-date="filterFrom ? new Date(filterFrom) : ''"
                :disabled="filterDisabled"
                text-input
                :locale="locale"
                six-weeks="center"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <div class="form-group col">
            <FormLabel html-for="filter_task_status">
              {{ t('client.reported_time.attributes.task_status') }}
            </FormLabel>
            <VueSelect
              v-model="filters.status"
              :get-option-label="(option: string) => t(`task.statuses.${option}`)"
              :options="taskStatusOptions"
              label="label"
              input-id="filter_task_status"
              :placeholder="t('common.not_selected')"
              :disabled="filterDisabled"
              :clearable="false"
            />
          </div>
          <div class="form-group col">
            <FormLabel html-for="filter_view_per_page">
              {{ t('client.reported_time.attributes.view_per_page') }}
            </FormLabel>
            <VueSelect
              v-model="filters.without_pagination"
              :options="viewPerPageOptions"
              label="label"
              input-id="filter_view_per_page"
              :placeholder="t('common.not_selected')"
              :disabled="filterDisabled"
              :clearable="false"
            />
          </div>
        </div>
        <div class="row d-flex align-items-end mt-3">
          <div class="col-3">
            <AppButton :disabled="filterDisabled">
              {{ t('common.apply_filters') }}
            </AppButton>
            <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="!filters.dirty">
              {{ t('common.reset_filters') }}
            </AppButton>
          </div>
        </div>
      </form>
      <div v-if="loader.isLoading.value" class="text-center">
        <AppLoader size="large" />
      </div>
      <template v-else>
        <AppAlert v-if="events.length === 0">{{ t('client.reported_time.empty') }}</AppAlert>
        <AppTable v-else hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh colspan="4"
                ><strong>{{ t('client.reported_time.attributes.total_filtered') }}</strong></AppTableTh
              >
              <AppTableTh>{{ convertMinutesToTime(getTime(events)['sum_estimated_time']) }}</AppTableTh>
              <AppTableTh>{{ convertMinutesToTime(getTime(events)['sum_tracked_time']) }}</AppTableTh>
              <AppTableTh>{{ convertMinutesToTime(getTime(events)['sum_billable_over_budget_time']) }}</AppTableTh>
              <AppTableTh>{{ convertMinutesToTime(getTime(events)['sum_not_billable_over_budget_time']) }}</AppTableTh>
              <AppTableTh colspan="6"></AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.project_name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.task_name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.week') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.employee') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.estimated_time') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.reported_time') }}</AppTableTh>
              <AppTableTh>{{ t('client.reported_time.attributes.billable_over_budget') }}</AppTableTh>
              <AppTableTh>{{ t('client.reported_time.attributes.not_billable_over_budget') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.task_status') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.task_note') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.billing_notes') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.time_report_comments') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="event in events" :key="event.id">
              <AppTableTd nowrap>
                <span v-if="event.eventable_type === 'project_task'" v-text="event.eventable_data.project?.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                {{ event.eventable_data.name }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{ event.week }}
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-text="event.user?.name" />
              </AppTableTd>
              <AppTableTd nowrap>
                {{ convertMinutesToTime(event.scheduled_time) }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{ convertMinutesToTime(event.tracked_time) }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{ convertMinutesToTime(event.billable_time) }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{ convertMinutesToTime(event.not_billable_time) }}
              </AppTableTd>
              <AppTableTd nowrap
                >{{ event.done_at ? t('task.statuses.done') : t('task.statuses.not_done') }}
              </AppTableTd>
              <AppTableTd nowrap> {{ event.eventable_data.note }}</AppTableTd>
              <AppTableTd nowrap>
                <span :key="index" v-for="(billing_note, index) in event.billing_notes">{{ billing_note }}<br /></span>
              </AppTableTd>
              <AppTableTd nowrap>
                <span :key="index" v-for="(note, index) in event.notes">{{ note }}<br /></span>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
        <AppTable class="mt-5" v-if="users_events.length !== 0">
          <AppTableHead>
            <AppTableTr>
              <AppTableTh
                ><strong>{{ t('client.reported_time.attributes.total_by_employee') }}</strong></AppTableTh
              >
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.employee') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.estimated_time') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.reported_time') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.billable_over_budget') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.reported_time.attributes.not_billable_over_budget') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="(user_events, user) in users_events" :key="user">
              <AppTableTd nowrap></AppTableTd>
              <AppTableTd nowrap
                ><strong>{{ user_events[0].user.name }}</strong></AppTableTd
              >
              <AppTableTd nowrap>{{ convertMinutesToTime(getTime(user_events)['sum_estimated_time']) }}</AppTableTd>
              <AppTableTd nowrap>{{ convertMinutesToTime(getTime(user_events)['sum_tracked_time']) }}</AppTableTd>
              <AppTableTd nowrap
                >{{ convertMinutesToTime(getTime(user_events)['sum_billable_over_budget_time']) }}
              </AppTableTd>
              <AppTableTd nowrap
                >{{ convertMinutesToTime(getTime(user_events)['sum_not_billable_over_budget_time']) }}
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </template>
    </AppBoxBody>
  </AppBox>
</template>
