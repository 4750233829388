<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { DateTime } from 'luxon';

import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  FontIcon,
  TimeEntryDropdownOptions,
  TimeSheetTimeCell,
} from '@/components';
import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';
import { EventType, IWeeklyReportedTimeResource } from '@/types/Event';
import { ActivityModalProps } from '@/types/Activity';
import { AddTrackedTimeEmitParams, ReportTimeEmitParams } from '@/types/TimeReportModals';
import api from '@/services/api';

type Props = {
  expanded: boolean;
  week: string;
  data: IWeeklyReportedTimeResource;
  collapsable?: boolean;
  editable?: boolean;
  creatable?: boolean;
  month?: number;
  userUuid: string;
};

const { expanded, week, data, collapsable = true, editable = true, creatable = true, userUuid } = defineProps<Props>();

const emit = defineEmits<{
  toggle: [week: string];
  activityCreate: [params: ActivityModalProps];
  activityEdit: [id: number];
  projectTaskCreate: [params: { initialWeek?: number }];
  projectTaskEdit: [id: number];
  projectTaskAddTrackedTime: [params: AddTrackedTimeEmitParams];
  activityAddTrackedTime: [params: AddTrackedTimeEmitParams];
  updated: [week: number];
}>();

const { t, d } = useI18n({ useScope: 'global' });
const { convertMinutesToTime, convertMinutesToShortTime } = useTime();
const { formatYearWeek } = useDate();

async function onReportTime(id: number, { time, date, setLoading, clearInput }: ReportTimeEmitParams, week: number) {
  try {
    setLoading(true);
    await api.events.reportTime(id, {
      time: time!,
      date,
      user_uuid: userUuid,
    });
    emit('updated', week);
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
    clearInput();
  }
}

function onAddTrackedTime(params: AddTrackedTimeEmitParams) {
  if (params.type === EventType.ProjectTask) {
    emit('projectTaskAddTrackedTime', params);
  } else if (params.type === EventType.Activity) {
    emit('activityAddTrackedTime', params);
  }
}
</script>

<template>
  <AppBox>
    <AppBoxBody class="p-0">
      <AppTable class="table-timesheet">
        <AppTableHead>
          <AppTableTr class="table-head-row">
            <AppTableTh class="pr-0" style="width: 48%" nowrap>
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0 text-neutral-900">
                  {{ t('dashboard.timesheet.timesheet') }} {{ formatYearWeek(week) }}
                </h4>
                <AppButton
                  v-if="collapsable"
                  class="mr-1"
                  size="small"
                  @click.prevent="emit('toggle', week)"
                  light
                  circle
                >
                  <FontIcon v-if="expanded" name="chevron-up" />
                  <FontIcon v-else name="chevron-down" />
                </AppButton>
              </div>
            </AppTableTh>
            <AppTableTh
              class="text-center p-1"
              style="width: 6%; vertical-align: top"
              v-for="(summary, day) in data.summary.days"
              :key="day"
              nowrap
              :class="{ 'background-neutral-300': month && DateTime.fromISO(day as string).month !== month }"
            >
              <span class="text-6">
                <span v-text="d(new Date(day), 'weekday')" />
                <span
                  :class="{ 'text-danger-500': data.working_hours.days[day].is_holiday }"
                  v-if="data.working_hours.days[day] && [1,2,3,4,5].includes(DateTime.fromISO(day as string).weekday)"
                >
                  <br />({{ convertMinutesToShortTime(data.working_hours.days[day].time) }})
                </span>
              </span>
            </AppTableTh>
            <AppTableTh nowrap class="text-center" style="width: 6%">
              <Dropdown v-if="creatable" placement="left" :distance="0">
                <AppButton color="secondary" light size="small" circle>
                  <FontIcon name="calendar-exclamation" />
                </AppButton>
                <template #popper="{ hide }">
                  <TimeEntryDropdownOptions
                    @on-absence="emit('activityCreate', $event)"
                    @on-project-task="emit('projectTaskCreate', $event)"
                    @on-internal="emit('activityCreate', $event)"
                    :hide="hide"
                    :initial-week="Number(week)"
                  />
                </template>
              </Dropdown>
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <template v-if="expanded">
            <AppTableTr v-for="event in data.events" :key="event.id">
              <AppTableTd>
                <div class="d-flex align-items-center"></div>
                <!-- Project task -->
                <strong v-if="event.type === EventType.ProjectTask" class="text-neutral-900">
                  <span class="pointer" v-if="editable" @click.prevent="emit('projectTaskEdit', event.id)">
                    {{ event.name }}
                  </span>
                  <span v-else>{{ event.name }}</span>
                </strong>

                <!-- Activity -->
                <strong
                  v-else-if="event.type === EventType.Activity"
                  :class="[event.is_absence ? 'text-danger-900' : 'text-neutral-500']"
                >
                  <span class="pointer" v-if="editable" @click.prevent="emit('activityEdit', event.id)">
                    {{ event.name }}
                  </span>
                  <span v-else>{{ event.name }}</span>
                </strong>
              </AppTableTd>
              <TimeSheetTimeCell
                v-for="(times, date) in event.days"
                :key="date"
                :date="date as string"
                :event-id="event.id"
                :event-type="event.type"
                :times="times"
                :editable="editable"
                :residual-time="event.residual_time"
                :is-absence="event.is_absence"
                :is-done="!!event.done_at"
                :is-quick-report-time-allowed="event.is_quick_report_time_allowed"
                @report-time="onReportTime(event.id, $event, +week)"
                @add-tracked-time="onAddTrackedTime($event)"
                @open-event="(id: number, type: EventType) => {
                  if (type === EventType.Activity) {
                    emit('activityEdit', id);
                  } else if (type === EventType.ProjectTask) {
                    emit('projectTaskEdit', id);
                  }
                }"
              />
              <AppTableTd class="text-center font-bold">
                {{ convertMinutesToTime(event.total) }}
              </AppTableTd>
            </AppTableTr>
          </template>
          <AppTableTr>
            <AppTableTd>
              <strong>{{ t('dashboard.timesheet.summary') }}</strong>
            </AppTableTd>
            <AppTableTd class="text-center font-bold px-1" v-for="(summary, day) in data.summary.days" :key="day">
              {{ convertMinutesToTime(summary) }}
            </AppTableTd>
            <AppTableTd class="text-center font-bold">
              {{ convertMinutesToTime(data.summary.total) }}
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </AppBoxBody>
  </AppBox>
</template>

<style scoped lang="scss">
.table-head-row {
  background-color: var(--color-neutral-100-hex);
  th {
    vertical-align: middle;
  }
}

.table-timesheet {
  td,
  th {
    border: 1px solid var(--color-neutral-200-hex);
  }
  tr {
    th,
    td {
      &:first-child {
        padding-left: var(--box-padding-base);
      }
    }
    th,
    td {
      &:last-child {
        padding-right: var(--box-padding-base);
      }
    }
  }
}
</style>
