<script setup lang="ts">
import { ComponentInstance, computed, ref } from 'vue';
import { AppTableTd, FontIcon, FormMinutesDuration } from '@/components';
import useTime from '@/composables/useTime';
import useLoading from '@/composables/useLoading';
import { EventType } from '@/types/Event';
import { AddTrackedTimeEmitParams, ReportTimeEmitParams } from '@/types/TimeReportModals';
import { DateTime } from 'luxon';

type Props = {
  editable: boolean;
  eventId: number;
  eventType: EventType;
  residualTime: number;
  isQuickReportTimeAllowed: boolean;
  date: string;
  isDone: boolean;
  isAbsence: boolean;
  times: {
    time: number;
    note: null | string;
  }[];
};

const { times, eventId, eventType, date, editable, residualTime, isQuickReportTimeAllowed, isDone, isAbsence } =
  defineProps<Props>();

const emit = defineEmits<{
  reportTime: [params: ReportTimeEmitParams];
  addTrackedTime: [params: AddTrackedTimeEmitParams];
  openEvent: [id: number, type: EventType];
}>();

const { convertMinutesToTime } = useTime();
const { loading, setLoading } = useLoading();
const time = ref<null | number>(null);
const timeDurationComponentRef = ref<ComponentInstance<typeof FormMinutesDuration>>();

function clearInput() {
  time.value = null;
}

function onChangeReportTime(time: null | number) {
  if (time === null) return;
  if (time <= residualTime) {
    emit('reportTime', {
      time,
      setLoading,
      clearInput,
      date,
    });
  } else {
    emit('addTrackedTime', { id: eventId, type: eventType, date, time });
    clearInput();
  }
}

function onClickCellHandler() {
  if (!canBeEdited.value) return;
  if (isDone || times.length > 0) {
    emit('openEvent', eventId, eventType);
  } else if (isQuickReportTimeAllowed) {
    timeDurationComponentRef.value?.focus();
  } else {
    emit('addTrackedTime', { id: eventId, type: eventType, date, time: 0 });
  }
}

const canBeEdited = computed(() => editable && (!isDone || times.length > 0) && !isFutureActivity.value);

const isFutureActivity = computed(() => {
  if (eventType === EventType.ProjectTask || isAbsence) {
    return false;
  }
  return DateTime.fromISO(date) >= DateTime.now();
});
</script>

<template>
  <AppTableTd class="text-center" :class="{ pointer: canBeEdited }" @click.stop="onClickCellHandler">
    <div v-for="(item, idx) in times" class="text-nowrap" :key="idx">
      {{ convertMinutesToTime(item.time) }}
      <FontIcon class="text-neutral-500" v-if="item.note" v-tooltip.bottom="item.note" name="info-circle-filled" />
    </div>
    <div
      class="d-flex align-items-center justify-content-center loadable"
      :class="{ 'is-loading': loading }"
      v-if="times.length === 0 && editable && !isDone && isQuickReportTimeAllowed && !isFutureActivity"
    >
      <FormMinutesDuration
        :disabled="loading"
        v-model="time"
        required
        :with-controls="false"
        placeholder=""
        size="small"
        style="background: none; border: none"
        :input-size="6"
        nullable
        @change="onChangeReportTime"
        ref="timeDurationComponentRef"
      />
    </div>
  </AppTableTd>
</template>
