export default {
  en: 'English',
  sv: 'Svenska',
  logout: 'Logout',
  login: 'Login',
  password: 'Password',
  password_confirm: 'Confirm password',
  email: 'Email',
  price: 'Price',
  price_per_hour: 'Price per hour',
  back: 'Back',
  apply_date: 'Apply date',
  update: 'Update',
  create: 'Create',
  delete: 'Delete',
  select: 'Select',
  not_selected: 'Not selected',
  no_results: 'No results',
  no_name: 'No name',
  no_search_results: 'No search results',
  actions: 'Actions',
  cancel: 'Cancel',
  fetch: 'Fetch',
  confirm: 'Confirm',
  add: 'Add',
  save: 'Save',
  clear: 'Clear',
  search: 'Search',
  previous: 'Previous',
  next: 'Next',
  apply: 'Apply',
  fixed: 'Fixed',
  hourly: 'Hourly',
  noname: 'Noname',
  activate: 'Activate',
  discount: 'Discount',
  yes: 'Yes',
  view: 'View',
  send: 'Send',
  user: 'User',
  reassign: 'Reassign',
  comments: 'Comments',
  no: 'No',
  empty: 'Empty',
  filters: 'Filters',
  apply_filters: 'Apply filters',
  reset_filters: 'Reset filters',
  reset: 'Reset',
  edit: 'Edit',
  approve: 'Approve',
  refuse: 'Refuse',
  month: 'Month',
  months: 'Months',
  year: 'Year',
  comment: 'Comment',
  week: 'Week',
  disabled: 'Disabled',
  close: 'Close',
  text: 'Text',
  total: 'Total',
  discard: 'Discard',
  all: 'All',
  loading: 'Loading...',
  type_of_search: 'Type to search or select from the list',
  week_short: 'W',
  move_week_forward: 'Move week forward',
  mark_week_as_done: 'Mark week as Done',
  planned: 'Planned',
  draft: 'Draft',
  undo: 'Undo',
  redo: 'Redo',
  deleted: 'Deleted',
  restore: 'Restore',
  add_time: 'Add time',
  type_to_search: 'Type to search',
  my_profile: 'My profile',
  created: 'Created',
  updated: 'Updated',
  forecasted: 'Forecasted',
  scheduled: 'Scheduled',
  to_project_planning: 'To project planning',
  add_new: 'Add new',
  personal_number: 'Personal ID number',
  phone: 'Phone',
  customer: 'Customer',
  show_more: 'Show more',
  show_less: 'Show less',
  none: 'None',

  confirms: {
    unsaved: {
      title: 'It looks like you have been editing something.',
      text: 'If you leave before saving, your changes will be lost. Are you sure you want to leave page?',
    },
    save: {
      title: 'Are you sure to save changes?',
    },
    cancel: {
      title: 'Are you sure to cancel changes?',
    },
    delete: {
      title: 'Delete "{name}"',
      text: 'Are you sure you want to delete <strong>{name}</strong>?',
    },
  },

  not_found: {
    title: '404 - Page Not Found',
    text: "The page you are looking for doesn't exist.",
  },

  messages: {
    has_been_created: '{name} has been created',
    has_been_updated: '{name} has been updated',
    have_been_updated: '{name} have been updated',
    has_been_added: '{name} has been added',
    has_been_deleted: '{name} has been deleted',
    has_been_closed: '{name} has been closed',
    has_been_activated: '{name} has been activated',
    forbidden_to_move: 'Forbidden to move',
  },

  project: 'Project',
  projects: 'Projects',

  task: 'Task',
  tasks: 'Tasks',

  tooltip: {
    edit: 'Edit "{name}"',
    delete: 'Delete "{name}"',
    complete: 'Complete',
    reopen: 'Reopen',
    prioritise: 'Prioritise',
    deprioritize: 'Deprioritize',
  },
  apply_filters_to_show_data: 'Apply filter to show data',
  support_in_weplan_for: 'Support in WePlan for',
};
