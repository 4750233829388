<script lang="ts" setup>
import { useTitle } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import {
  AppAlert,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FixedPriceIndexModal,
  FontIcon,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import { IFixedPriceSettingsResource } from '@/types/Service';
import api from '@/services/api';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import toast from '@/services/toast';

const { t, d } = useI18n({ useScope: 'global' });

const title = useTitle(computed(() => t('service.fixed_price_settings.index.title')));

const loader = useLoader();

const fixedPriceSettings = ref<IFixedPriceSettingsResource[]>([]);

async function getFixedPriceSettings(withLoading = true) {
  try {
    if (withLoading) {
      loader.start();
    }
    const response = await api.services.fixedPriceSettings.index();
    fixedPriceSettings.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

function onCreate() {
  const { open, close, destroy } = useModal({
    component: FixedPriceIndexModal,
    attrs: {
      onCreated() {
        getFixedPriceSettings(false);
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(fixedPriceSetting: IFixedPriceSettingsResource) {
  const { open, close, destroy } = useModal({
    component: FixedPriceIndexModal,
    attrs: {
      fixedPriceSetting,
      onUpdated() {
        getFixedPriceSettings(false);
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(fixedPriceSetting: IFixedPriceSettingsResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('service.fixed_price_settings.confirm.delete.title'),
      message: t('service.fixed_price_settings.confirm.delete.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.services.fixedPriceSettings.delete(fixedPriceSetting.id);
          fixedPriceSettings.value = fixedPriceSettings.value.filter(({ id }) => id !== fixedPriceSetting.id);
          toast.success(t('common.deleted'));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(getFixedPriceSettings);
</script>

<template>
  <div class="container-wide">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center mb-3">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="service.fixed_price_settings.index.help" />
      </div>
      <AppButton color="secondary" @click.prevent="onCreate" :disabled="loader.isLoading.value">
        <FontIcon name="circle-plus" />
        {{ t('common.add_new') }}
      </AppButton>
    </div>

    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="fixedPriceSettings.length === 0">
        {{ t('common.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('service.fixed_price_settings.attributes.date') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('service.fixed_price_settings.attributes.annual_increase') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="fixedPriceSetting in fixedPriceSettings" :key="fixedPriceSetting.id">
              <AppTableTd nowrap><strong v-text="d(fixedPriceSetting.date)" /></AppTableTd>
              <AppTableTd nowrap> {{ fixedPriceSetting.annual_increase }}% </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  @click.stop.prevent="onEdit(fixedPriceSetting)"
                  size="small"
                  light
                  circle
                  v-tooltip.left="t('common.edit')"
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  @click.stop.prevent="onDelete(fixedPriceSetting)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                  v-tooltip.left="t('common.delete')"
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </template>
    </template>
  </div>
</template>
