<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { AppAvatar, AppTableTd } from '@/components';
import useTime from '@/composables/useTime';
import { IClientPlanningPeriodWeek, IClientPlanningProjectEvent } from '@/types/Client';

const { periodWeek, weekCurrent, droppable, events } = defineProps<{
  periodWeek: IClientPlanningPeriodWeek;
  weekCurrent: boolean;
  droppable: boolean;
  events: IClientPlanningProjectEvent[];
}>();

const emit = defineEmits<{
  drop: [evt: DragEvent];
  edit: [event: IClientPlanningProjectEvent];
  dragstart: [evt: DragEvent, event: IClientPlanningProjectEvent];
  dragend: [evt: DragEvent];
}>();

const { convertMinutesToTime } = useTime();
const { t } = useI18n({ useScope: 'global' });

function getEventStatus(event: IClientPlanningProjectEvent) {
  return event.is_done ? 'is-done' : event.total_tracked_time ? 'is-progress' : '';
}

function isDraggable(event: IClientPlanningProjectEvent) {
  return !(event.time_sheet_locked || event.total_tracked_time !== 0 || event.is_done);
}

function onWeekOver(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.week-cell.droppable');
  if (element) element.classList.add('over');
}

function onWeekLeave(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.week-cell.droppable');
  if (element) element.classList.remove('over');
}
</script>

<template>
  <AppTableTd
    class="week-cell"
    :colspan="periodWeek.count_months"
    nowrap
    style="vertical-align: top"
    :class="[{ droppable }, { 'background-neutral-50': weekCurrent }]"
    @dragenter.prevent
    @drop.prevent="emit('drop', $event)"
    @dragover.prevent="onWeekOver($event)"
    @dragleave.prevent="onWeekLeave($event)"
  >
    <div
      :draggable="isDraggable(event)"
      @dragstart="emit('dragstart', $event, event)"
      @dragend="emit('dragend', $event)"
      :key="event.id"
      class="event-item"
      :id="`event-element-${event.id}`"
      v-for="event in events"
      @click.prevent="emit('edit', event)"
      :class="[getEventStatus(event), { draggable: isDraggable(event) }]"
      v-tooltip.bottom="{
        content: `<div>${t('time-entry.modal.attributes.employee')}: ${event.user?.name ?? t('common.none')}</div>${t(
          'time-entry.modal.attributes.task',
        )}: ${event.name}`,
        html: true,
      }"
    >
      <AppAvatar v-if="event.user" :name="event.user.name" class="mr-2" v-tooltip="event.user.name" size="small" />
      <span>
        {{ convertMinutesToTime(event.total_tracked_time) }} /
        {{ convertMinutesToTime(event.scheduled_time) }}
      </span>
    </div>
  </AppTableTd>
</template>

<style scoped lang="scss">
.event-item {
  border-width: 1px;
  border-style: solid;
  padding: 0.25rem;
  border-radius: var(--radius-base);
  display: flex;
  align-items: center;
  font-size: 85%;
  font-weight: 600;
  border-color: transparent;
  background-color: var(--color-neutral-100-hex);
  color: var(--color-neutral-900-hex);
  user-select: none;
  cursor: pointer;
  &.is-done {
    border-color: transparent;
    background-color: var(--color-success-100-hex);
    color: var(--color-success-900-hex);
  }
  &.is-progress {
    border-color: transparent;
    background-color: var(--color-warning-100-hex);
    color: var(--color-warning-900-hex);
  }

  + .event-item {
    margin-top: 0.5rem;
  }
}

.draggable {
  &.dragging {
    opacity: 0.25;
    background-color: white;
  }
}
.droppable {
  &.over {
    background-color: var(--color-neutral-100-hex);
  }
}
</style>
